<template>
	<div id="webPage">
		<div class="webpage">
			<div class="webpage-menu-btn">
				<a-space>
					<a-button v-if="is_default != 'home'" type="primary" @click="saveHomePage(1)">保存并设置为默认首页</a-button>
					<a-button type="primary" @click="saveHomePage(is_default)">保存</a-button>
					<a-button style="margin-right: 60px;" @click="$router.go(-1)">返回</a-button>
				</a-space>
			</div>
			
			<div class="custom-storepage">
				<!-- 基础组件 -->
				<div class="basic-component">
					<!-- 模块 -->
					<div class="entryBtnsList" v-if="basicShow">
						<div class="funPanelEntryBtn funPanelEntryBtn_module" @click="basicShow = false">
							<p class="funPanelEntryBtnSvg ri-apps-2-line"></p>
							<span class="funPanelEntryBtnText">模块</span>
						</div>
					</div>
					<div class="assemblylist" v-if="!basicShow">
						<div class="basic-component-title">
							<p>基础组件</p>
							<p class="ri-close-circle-line" @click="basicShow = true"></p>
						</div>
						<div class="basic-component-ul">
							<a-collapse v-model:activeKey="activeNames" :bordered="false" style="width:100%">
								<a-collapse-panel header="基础" key="1">
									<div class="basic-component-ul-con">
										<!-- <div class="basic-component-ul-li" @click="addElement('topbar')">
											<img src="@/assets/img/png/dh.png" alt="">
											<div>顶部导航</div>
										</div> -->
										<div class="basic-component-ul-li" @click="addElement('sidenav')">
											<img src="@/assets/img/png/功能导航.png" alt="">
											<div>侧边导航</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('swiper')">
											<img style="width: 35px;" src="@/assets/img/png/轮播图.png" alt="">
											<div>轮播广告</div>
										</div>
										<!-- <div class="basic-component-ul-li" @click="addElement('funav')">
											<img src="@/assets/img/png/功能导航.png" alt="">
											<div>功能导航</div>
										</div> -->
										<div class="basic-component-ul-li" @click="addElement('diyimg')">
											<img src="@/assets/img/png/DIY.png" alt="">
											<div>DIY图片</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('textlist')">
											<img src="@/assets/img/png/图文列表.png" alt="">
											<div>图文列表</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('titletext')">
											<img src="@/assets/img/png/标题.png" alt="">
											<div>标题文字</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('monitor')">
											<img src="@/assets/img/png/监控.png" alt="">
											<div>监控组件</div>
										</div>
										<!-- <div class="basic-component-ul-li" @click="show_foter=true">
											<img src="@/assets/img/png/关于我们.png" alt="">
											<div>底部</div>
										</div> -->
									</div>
									
								</a-collapse-panel>
								<a-collapse-panel header="常用" key="2">
									<div class="basic-component-ul-con">
										<div class="basic-component-ul-li" @click="addElement('recomshop')">
											<img src="@/assets/img/png/优先推荐.png" alt="">
											<div>优先推荐</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('optshop')">
											<img src="@/assets/img/png/优选店铺.png" alt="">
											<div>优选店铺</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('land')">
											<img src="@/assets/img/png/功能导航.png" alt="">
											<div>租地种植</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('adopt')">
											<img src="@/assets/img/png/畜牧认养.png" alt="">
											<div>畜牧领养</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('hot')">
											<img src="@/assets/img/png/热销产品.png" alt="">
											<div>热销产品</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('product')">
											<img src="@/assets/img/png/新品上架.png" alt="">
											<div>新品上架</div>
										</div>
										<div class="basic-component-ul-li" @click="addElement('news')">
											<img src="@/assets/img/png/新闻资讯.png" alt="">
											<div>新闻资讯</div>
										</div>
									</div>
									
								</a-collapse-panel>
							</a-collapse>
						</div>
						
					</div>
				</div>
				
				<!-- 页面显示 -->
				<div class="mianWrapper">
					
					<topbar :topbarList='topbarList' @changeCurrent='changeCurrent'></topbar>
					
					<!-- 首页定制顶部 -->
					<div class="active_wrapper">
					    <!-- <draggable element="ul" v-model="list"> -->
					        <li v-for="(item, index) in list" :key="index" @click="selectCurrentType($event,item.id)"  class="active_wrapper_li" ref="selectDom">
								<!-- <topbar v-if="item.type === 'topbar'" @changeDelet='showDeleteWrapper' :topbarList='item'></topbar> -->
								
								<swiper v-if="item.type === 'swiper'" @changeDelet='showDeleteWrapper' :swiperlist='item'></swiper>
								
								<funav v-if="item.type === 'funav'" @changeDelet='showDeleteWrapper' :functionid='item.id' :function_type='item.function_type' :SelectColor='item.SelectColor' :SelectextColor='item.SelectextColor' :oneColor='item.oneColor' :twoColor='item.twoColor' :threeColor='item.threeColor' :fourColor='item.fourColor' :fiveColor='item.fiveColor' :sixColor='item.sixColor'></funav>
								
								<recomshop v-if="item.type === 'recomshop'" @changeDelet='showDeleteWrapper' :recomshoplist='item'></recomshop>
								
								<optshop v-if="item.type === 'optshop'" @changeDelet='showDeleteWrapper' :optshopid='item.id' :optshoptype='item.optshop_type' :rows='item.rows'></optshop>
							
								<land v-if="item.type === 'land'" @changeDelet='showDeleteWrapper' :landid='item.id' :landList='item'></land>
								
								<adopt v-if="item.type === 'adopt'" @changeDelet='showDeleteWrapper' :adoptid='item.id' :adoptList='item'></adopt>
								
								<hot v-if="item.type === 'hot'" @changeDelet='showDeleteWrapper' :hotList='item'></hot>
								
								<product v-if="item.type === 'product'" @changeDelet='showDeleteWrapper' :productList='item'></product>
								
								<news v-if="item.type === 'news'" @changeDelet='showDeleteWrapper' :newsList='item'></news>
								
								<diyimg v-if="item.type === 'diyimg'" @changeDelet='showDeleteWrapper' :diyimgList='item'></diyimg>
								
								<textlist v-if="item.type === 'textlist'" @changeDelet='showDeleteWrapper' :textList='item'></textlist>
								
								<title-text v-if="item.type === 'titletext'" @changeDelet='showDeleteWrapper' :titletextList='item'></title-text>
								
								<monitor v-if="item.type === 'monitor'" @changeDelet='showDeleteWrapper' :monitorList='item'></monitor>
								
								<sidenav v-if="item.type === 'sidenav'" :sidenavList='item'></sidenav>
							</li>
					    <!-- </draggable> -->
					    <div class="none-element" v-if="list.length==0">您还没有添加任何元素</div>
					</div>
					<!-- <foter v-if="show_foter" @changeCurrentItem='changeCurrentItem' :foterList='foter'></foter> -->
				</div>
					
				<!-- 右边设置 -->
				<div class="eddit_wrapper" v-if="showWrapper" ref="myRef" @mousedown="mouseDown($event)" @mouseup="mouseUpHand($event)">
					<!-- <GeminiScrollbar class='scroll-bar'> -->
						<div class="pageclose" @click="closewrapper">
							<p class="ri-close-circle-line"></p>
						</div>
						<!-- 底部 -->
						<div v-if="currentId == 0">
							<div class="commonpent_title">底部导航</div>
							<div style="height: 50px;"></div>
							<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
								<a-form-item label="开启">
								    <a-switch v-model:checked="show_foter"></a-switch>
								</a-form-item>
								<a-form-item label="公众号">
									<div class="data_item">
										<div class="item-img">
											<img :src="foter.accounturl" alt="">
										</div>
										<div class="input-item">
											<kd-img-select :src="foter.accounturl" @success="e=>foter.accounturl = e"></kd-img-select>
										</div>
									</div>
								</a-form-item>
								<a-form-item label="微信">
									<div class="data_item">
										<div class="item-img">
											<img :src="foter.wechaturl" alt="">
										</div>
										<div class="input-item">
											<kd-img-select :src="foter.wechaturl" @success="e=>foter.wechaturl = e"></kd-img-select>
										</div>
									</div>
								</a-form-item>
								<a-form-item label="IOS">
									<div class="data_item">
										<div class="item-img">
											<img :src="foter.iosurl" alt="">
										</div>
										<div class="input-item">
											<kd-img-select :src="foter.iosurl" @success="e=>foter.iosurl = e"></kd-img-select>
										</div>
									</div>
								</a-form-item>
								<a-form-item label="Android">
									<div class="data_item">
										<div class="item-img">
											<img :src="foter.androidurl" alt="">
										</div>
										<div class="input-item">
											<kd-img-select :src="foter.androidurl" @success="e=>foter.androidurl = e"></kd-img-select>
										</div>
									</div>
								</a-form-item>
								<a-form-item label="小程序二维码">
									<div class="data_item">
										<div class="item-img">
											<img :src="foter.codeurl" alt="">
										</div>
										<div class="input-item">
											<kd-img-select :src="foter.codeurl" @success="e=>foter.codeurl = e"></kd-img-select>
											<a-input placeholder="提示文字" v-model:value="foter.codetitle"></a-input>
										</div>
									</div>
								</a-form-item>
								<a-form-item label="手机号码">
									<a-input placeholder="填写电话" v-model:value="foter.fotphone"></a-input>
								</a-form-item>
								<a-form-item label="工作时间">
									<a-input placeholder="填写工作时间" v-model:value="foter.fotdate"></a-input>
								</a-form-item>
								<a-form-item label="邮箱">
									<a-input placeholder="填写邮箱" v-model:value="foter.mailbox"></a-input>
								</a-form-item>
								<a-form-item label="底部备案">
									<a-input placeholder="填写备案信息" v-model:value="foter.record"></a-input>
								</a-form-item>
							</a-form>
							
						</div>
						<div v-if="currentId == -1">
							<div class="commonpent_title" style="margin-bottom: 10px;">导航栏</div>
							<div style="height: 50px;"></div>
						    <a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
								<a-form-item label="页面标题">
									<a-input v-model:value="name"></a-input>
								</a-form-item>
						    </a-form>
						</div>
						<div  class="banner_container" v-for="(item,index) in list" :key="index" >
							<!-- 顶部导航 -->
							<!-- <div v-if="'topbar'==currentType && item.id==currentId">
								<div class="commonpent_title" style="margin-bottom: 10px;">导航栏</div>
								<div style="height: 50px;"></div>
							    <el-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<el-form-item label="导航样式">
										<el-radio v-model="item.navtype" :label="1">样式一</el-radio>
										<el-radio v-model="item.navtype" :label="2">样式二</el-radio>
									</el-form-item>
									<div style="margin-left: 40px;margin-bottom: 18px;">
										<p v-if="item.navtype == 1" style="color: #67C23A;">提示：样式一趋向于商城头部</p>
										<p v-if="item.navtype == 2" style="color: #67C23A;">提示：样式二趋向于网站官网</p>
									</div>
									
									<el-form-item label="页面标题">
										<el-input v-model="name"></el-input>
									</el-form-item>
									<el-form-item label="logo图片">
										<el-input v-model="item.logo">
											<el-button slot="append"  @click="uploadImg(-1)">选择图片</el-button>
										</el-input>
									</el-form-item>
									<div style="margin-left: 40px;margin-bottom: 18px;">
										<p style="color: red;">提示：logo大小建议(205×70)</p>
									</div>
							    </el-form>
								<div class="data-list" v-if="item.navtype == 2">
								    <draggable element="div" v-model="item.list">
								        <div class="data_item" v-for="(val,ind) in item.list" :key="ind">
								            <div class="input-item">
								                <el-input v-model="val.title"></el-input>
								                <el-input v-model="val.path">
								                    <el-button slot="append" @click="showLink(ind)">选择链接</el-button>
								                </el-input>
								            </div>
								            <i class="ri-close-circle-line item_close" @click="deleteList(ind,'确认删除该图标标题吗？')"></i>
								        </div>
								    </draggable>
								    <div v-if="item.list.length <= 5" class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
								</div>
							</div> -->
							
							<!-- 轮播图 -->
							<div class="wp-form-con" v-if="'swiper'==currentType && item.id==currentId">
							    <div class="commonpent_title">轮播图</div>
								<div style="height: 50px;"></div>
							    <a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="轮播样式">
										<a-radio-group v-model:value="item.slide_type">
									    	<a-radio :value="1">目录样式</a-radio>
									    	<a-radio :value="2">视频播放</a-radio>
											<a-radio :value="3">样式三</a-radio>
										 </a-radio-group>
									</a-form-item>
							        <a-form-item label="焦点颜色" v-if="item.slide_type == 1">
										<kd-color v-model="item.focusColor"></kd-color>
							        </a-form-item>
							        <a-form-item label="显示焦点" v-if="item.slide_type == 1">
										<a-radio-group v-model:value="item.focus">
											<a-radio :value="true">显示</a-radio>
											<a-radio :value="false">不显示</a-radio>
										</a-radio-group>
							        </a-form-item>
									<a-form-item label="视频地址" v-if="item.slide_type == 2">
										<a-input v-model:value="item.videourl" placeholder="请填写视频地址"></a-input>
									</a-form-item>
									<a-form-item label="标题文字" v-if="item.slide_type == 3">
										<a-input v-model:value="item.titletext" placeholder="请填写标题文字"></a-input>
									</a-form-item>
									<a-form-item label="副标题文字" v-if="item.slide_type == 3">
										<a-input v-model:value="item.vicetitle" placeholder="请填写副标题文字"></a-input>
									</a-form-item>
							    </a-form>
							    <div class="data-list" v-if="item.slide_type != 2">
							        <!-- <draggable element="div" v-model="item.list"> -->
										<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
											<template #item="{element,index}">
												<div class="data_item">
													<div class="item-img" style="width: 130px;">
														<img :src="element.url" alt="" style="width: 130px;">
													</div>
													<div class="input-item">
														<kd-img-select :show-img="false" :src="element.url" @success="e=>element.url = e"></kd-img-select>
													</div>
													<i class="ri-close-circle-line item_close" @click="deleteList(index,'确认删除该轮播图吗？')"></i>
												</div>
											</template>
										</draggable>
							        <div class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
							    </div>
								
							</div>
							
							<!-- 功能导航 -->
							<div v-if="'funav'==currentType && item.id==currentId">
							    <div class="commonpent_title">功能导航</div>
								<div style="height: 50px;"></div>
							    <a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.function_type">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="选中背景颜色">
										<kd-color v-model="item.SelectColor"></kd-color>
									</a-form-item>
									<a-form-item label="选中字体颜色">
										<kd-color v-model="item.SelectextColor"></kd-color>
									</a-form-item>
							        <a-form-item label="一号字体颜色" v-if="item.function_type == 1">
										<kd-color v-model="item.oneColor"></kd-color>
							        </a-form-item>
									<a-form-item label="二号字体颜色" v-if="item.function_type == 1">
										<kd-color v-model="item.twoColor"></kd-color>
									</a-form-item>
									<a-form-item label="三号字体颜色" v-if="item.function_type == 1">
										<kd-color v-model="item.threeColor"></kd-color>
									</a-form-item>
									<a-form-item label="四号字体颜色" v-if="item.function_type == 1">
										<kd-color v-model="item.fourColor"></kd-color>
									</a-form-item>
									<a-form-item label="五号字体颜色" v-if="item.function_type == 1">
										<kd-color v-model="item.fiveColor"></kd-color>
									</a-form-item>
									<a-form-item label="六号字体颜色" v-if="item.function_type == 1">
										<kd-color v-model="item.sixColor"></kd-color>
									</a-form-item>
							    </a-form>
							</div>
							
							<!-- 优先推荐 -->
							<div v-if="'recomshop'==currentType && item.id==currentId">
								<div class="commonpent_title">优先推荐</div>
								<div style="height: 50px;"></div>
								
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.recomshop_type">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
											<a-radio :value="4">样式四</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="展示数量" v-if="item.recomshop_type == 1 || item.recomshop_type == 2">
									    <a-input v-model:value="item.rows" size="small"></a-input>
									</a-form-item>
									<a-form-item label="标题颜色">
										<kd-color v-model="item.titleColor"></kd-color>
									</a-form-item>
									<a-form-item label="价格颜色">
										<kd-color v-model="item.priceColor"></kd-color>
									</a-form-item>
									<a-form-item label="销量">
										<a-switch v-model:value="item.salesvolume"></a-switch>
									</a-form-item>
									<a-form-item label="广告图" v-if="item.recomshop_type == 3">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.url" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :show-img="false" :src="item.url" @success="e=>item.url = e"></kd-img-select>
												<a-input v-model:value="item.path">
													<template #addonAfter>
														<div @click="showLink(0)">选择链接</div>
													</template>
												</a-input>
											</div>
										</div>
									</a-form-item>
								</a-form>
							</div>
							
							<!-- 优选店铺 -->
							<div v-if="'optshop'==currentType && item.id==currentId">
								<div class="commonpent_title">优先推荐</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.optshop_type">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="展示数量">
									    <a-input v-model:value="item.rows"></a-input>
										<p style="color: red;font-size: 12px;">提示：最多展示6个优选店铺</p>
									</a-form-item>
								</a-form>
								
							</div>
							
							<!-- 租地种植 -->
							<div v-if="'land'==currentType && item.id==currentId">
								<div class="commonpent_title">租地种植</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="租地样式">
										<a-radio-group v-model:value="item.landtype">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
											<a-radio :value="4">样式四</a-radio>
										</a-radio-group>
										<p style="color: red;font-size: 12px;" v-if="item.landtype != 3">提示：默认展示前20个地块</p>
										<p style="color: red;font-size: 12px;" v-if="item.landtype == 3">提示：默认展示前8个地块</p>
									</a-form-item>
									<a-form-item label="标题颜色">
										<kd-color v-model="item.titleColor"></kd-color>
									</a-form-item>
									<a-form-item label="简介颜色">
										<kd-color v-model="item.briefColor"></kd-color>
									</a-form-item>
									<a-form-item label="价格颜色">
										<kd-color v-model="item.priceColor"></kd-color>
									</a-form-item>
									<a-form-item label="广告图" v-if="item.landtype == 3 || item.landtype == 4">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.url" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :show-img="false" :src="item.url" @success="e=>item.url = e"></kd-img-select>
												<a-input v-model:value="item.path">
													<template #addonAfter>
														<div @click="showLink(0)">选择链接</div>
													</template>
												</a-input>
											</div>
										</div>
									</a-form-item>
								</a-form>
							</div>
							
							<!-- 畜牧认养 -->
							<div v-if="'adopt'==currentType && item.id==currentId">
								<div class="commonpent_title">畜牧认养</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.adoptype">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
											<a-radio :value="4">样式四</a-radio>
										</a-radio-group>
										<p style="color: red;font-size: 12px;" v-if="item.adoptype != 3">提示：默认展示前20个认养</p>
										<p style="color: red;font-size: 12px;" v-if="item.adoptype == 3">提示：默认展示前8个认养</p>
									</a-form-item>
									<a-form-item label="标题颜色">
										<kd-color v-model="item.titleColor"></kd-color>
									</a-form-item>
									<a-form-item label="价格颜色">
										<kd-color v-model="item.titleColor"></kd-color>
									</a-form-item>
									<a-form-item label="广告图" v-if="item.adoptype == 3 || item.adoptype == 4">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.url" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :show-img="false" :src="item.url" @success="e=>item.url = e"></kd-img-select>
												<a-input v-model:value="item.path">
													<template #addonAfter>
														<div @click="showLink(0)">选择链接</div>
													</template>
												</a-input>
											</div>
										</div>
									</a-form-item>
								</a-form>
							</div>
							
							<!-- 热销产品 -->
							<div v-if="'hot'==currentType && item.id==currentId">
								<div class="commonpent_title">热销产品</div>
								<div style="height: 50px;"></div>
								
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.hotype">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
											<a-radio :value="4">样式四</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="展示数量" v-if="item.hotype == 1 || item.hotype == 2">
									    <a-input v-model="item.rows" size="small"></a-input>
									</a-form-item>
									<a-form-item label="标题颜色">
										<kd-color v-model="item.titleColor"></kd-color>
									</a-form-item>
									<a-form-item label="价格颜色">
										<kd-color v-model="item.priceColor"></kd-color>
									</a-form-item>
									<a-form-item label="销量">
										<a-switch v-model:checked="item.salesvolume"></a-switch>
									</a-form-item>
									<a-form-item label="广告图" v-if="item.hotype == 3">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.url" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :src="item.url" @success="e=>item.url = e"></kd-img-select>
												<a-input v-model:value="item.path">
													<template #addonAfter>
														<div @click="showLink(0)">选择链接</div>
													</template>
												</a-input>
											</div>
										</div>
									</a-form-item>
								</a-form>
							</div>
							
							<!-- 新品上架 -->
							<div v-if="'product'==currentType && item.id==currentId">
								<div class="commonpent_title">新品上架</div>
								<div style="height: 50px;"></div>
								
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.productype">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
											<a-radio :value="4">样式四</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="标题颜色">
										<kd-color v-model="item.titleColor"></kd-color>
									</a-form-item>
									<a-form-item label="价格颜色">
										<kd-color v-model="item.priceColor"></kd-color>
									</a-form-item>
									<a-form-item label="销量">
										<a-switch v-model:checked="item.salesvolume"></a-switch>
									</a-form-item>
									<a-form-item label="广告图" v-if="item.productype == 3">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.url" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :src="item.url" @success="e=>item.url = e"></kd-img-select>
												<a-input v-model:value="item.path">
													<template #addonAfter>
														<div @click="showLink(0)">选择链接</div>
													</template>
												</a-input>
											</div>
										</div>
									</a-form-item>
								</a-form>
							</div>
							
							<!-- 新闻资讯 -->
							<div class="wp-form-con" v-if="'news'==currentType && item.id==currentId">
								<div class="commonpent_title">新闻资讯</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="添加背景图片">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.url" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :show-img="false" :src="item.url" @success="e=>item.url = e"></kd-img-select>
											</div>
										</div>
									</a-form-item>
									<a-form-item label="添加侧边展示图片">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.rightimg" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :show-img="false" :src="item.rightimg" @success="e=>item.rightimg = e"></kd-img-select>
											</div>
										</div>
									</a-form-item>
									<div class="data-list">
											<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
												<template #item="{element,index}">
													<div class="data_item">
														<div class="item-img">
															<img :src="element.cover" :alt="element.cover">
															<div class="txt">
																<com-select-table-list data-type='information' :data-index="index" @getData="getTableData">
																	选择资讯
																</com-select-table-list>
															</div>
														</div>
														<div class="input-item">
															<a-input v-model="element.title" readonly="" addon-before="标题"></a-input>
														</div>
														<i class="ri-close-circle-line item_close" @click="deleteList(index,'确认删除该资讯吗？')"></i>
													</div>
												</template>
											</draggable>
									    <div class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
									</div>
								</a-form>
							</div>
							
							<!-- DIY图片 -->
							<div class="wp-form-con" v-if="'diyimg'==currentType && item.id==currentId">
								<div class="commonpent_title">DIY图片</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.diyimgtype">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
											<a-radio :value="4">样式四</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item>
										<p style="color: red;" v-if="item.diyimgtype == 1">提示：为了样式需求最好上传五张图片</p>
										<p style="color: red;" v-if="item.diyimgtype == 2">提示：为了样式需求最好上传四张图片</p>
									</a-form-item>
									<a-form-item label="添加图片" v-if="item.diyimgtype == 1">
											<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
												<template #item="{element,index}">
													<div class="data_item" >
														<div class="item-img">
															<img :src="element.url" alt="">
														</div>
														<div class="input-item">
															<kd-img-select :show-img="false" :src="element.url" @success="e=>element.url = e"></kd-img-select>
															<a-input v-model:elementue="element.path">
																<template #addonAfter>
																	<div @click="showLink(index)">选择链接</div>
																</template>
															</a-input>
														</div>
														<i class="ri-close-circle-line item_close" @click="deleteList(index,'确认删除该图片吗？')"></i>
													</div>
												</template>
											</draggable>
											<div class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
									</a-form-item>
									
									<a-form-item label="添加图片" v-if="item.diyimgtype == 2">
										<!-- <draggable element="div"> -->
											<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
												<template #item="{element,index}">
													<div class="data_item">
														<div class="item-img">
															<img :src="element.url" alt="">
															<div class="txt" @click="uploadImg(index)">选择图片</div>
														</div>
														<div class="input-item">
															<a-input placeholder="标题" v-model:value="element.title"></a-input>
															<a-input v-model:value="element.path">
																<template #addonAfter>
																	<div @click="showLink(index)">选择链接</div>
																</template>
															</a-input>
														</div>
														<i class="ri-close-circle-line item_close" @click="deleteList(index,'确认删除该图片吗？')"></i>
													</div>
												</template>
											</draggable>
											<div class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
										<!-- </draggable> -->
									</a-form-item>
									
									<a-form-item label="添加图片" v-if="item.diyimgtype == 3 || item.diyimgtype == 4">
										<!-- <draggable element="div"> -->
											<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
												<template #item="{element,index}">
													<div class="data_item" >
														<div class="item-img">
															<img :src="element.url" alt="">
														</div>
														<div class="input-item">
															<kd-img-select :src="element.url" @success="e=>element.url = e"></kd-img-select>
															<a-input v-model:value="element.path">
																<template #addonAfter>
																	<div @click="showLink(index)">选择链接</div>
																</template>
															</a-input>
														</div>
														<i class="ri-close-circle-line item_close" @click="deleteList(index,'确认删除该图片吗？')"></i>
													</div>
												</template>
											</draggable>
											<div class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
										<!-- </draggable> -->
									</a-form-item>
									
								</a-form>
								
							</div>
							
							<!-- 图文列表 -->
							<div class="wp-form-con"  v-if="'textlist'==currentType && item.id==currentId">
								<div class="commonpent_title">图文列表</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.textype">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="添加列表">
										<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
											<template #item="{element,index}">
												<div class="data_item">
													<div class="item-img">
														<img :src="element.url" alt="">
														<div class="txt" @click="uploadImg(index)">
															<kd-img-select :custom="true" :src="element.url" @success="e=>element.url = e">
																选择图片
															</kd-img-select>
														</div>
													</div>
													<div class="input-item">
														<a-input placeholder="标题" v-model:value="element.title"></a-input>
														<a-input v-model:value="element.path">
															<template #addonAfter>
																<div @click="showLink(index)">选择链接</div>
															</template>
														</a-input>
													</div>
													<div v-if="item.textype == 2">更多<a-switch v-model:checked="element.more"></a-switch></div>
													<div class="input-item input-text" v-if="element.cont">
														<tinymce-editor v-model="element.cont"></tinymce-editor>
													</div>
													<i class="ri-close-circle-line item_close" @click="deleteList(index,'确认删除该列表吗？')"></i>
												</div>
											</template>
										</draggable>
										<div class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
									</a-form-item>
									
								</a-form>
							</div>
							
							<!-- 文字标题 -->
							<div v-if="'titletext'==currentType && item.id==currentId">
								<div class="commonpent_title">文字列表</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="排版样式">
										<a-radio-group v-model:value="item.titletextype">
											<a-radio :value="1">样式一</a-radio>
											<a-radio :value="2">样式二</a-radio>
											<a-radio :value="3">样式三</a-radio>
											<a-radio :value="4">样式四</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item :wrapper-col="{offset:4}">
										<a-radio-group v-model:value="item.positiontype">
											<a-radio :value="1">居左</a-radio>
											<a-radio :value="2">居中</a-radio>
											<a-radio :value="3">居右</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="标题内容">
										<a-input placeholder="请输入标题内容" v-model:value="item.title"></a-input>
										<kd-color v-model="item.titleColor"></kd-color>
										<a-slider v-model:value="item.fontSize" :min="16" :max="22"></a-slider>
									</a-form-item>
									<a-form-item label="副标题内容">
										<a-input placeholder="请输入副标题内容" v-model:value="item.vicetitle"></a-input>
										<kd-color v-model="item.vicetitleColor"></kd-color>
									</a-form-item>
									<a-form-item label="小标题内容" v-if="item.titletextype != 3">
										<a-input placeholder="请输入小标题内容" v-model:value="item.smalltitle"></a-input>
										<kd-color v-model="item.smalltitleColor"></kd-color>
									</a-form-item>
									<a-form-item label="分割线颜色" v-if="item.titletextype != 3">
										<kd-color v-model="item.lineColor"></kd-color>
									</a-form-item>
									<a-form-item label="左图标" v-if="item.titletextype == 3">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.lefturl" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :src="item.url" @success="e=>item.url = e"></kd-img-select>
											</div>
										</div>
									</a-form-item>
									<a-form-item label="右图标" v-if="item.titletextype == 3">
										<div class="data_item">
											<div class="item-img">
												<img :src="item.righturl" alt="">
											</div>
											<div class="input-item">
												<kd-img-select :src="item.url" @success="e=>item.url = e"></kd-img-select>
											</div>
										</div>
									</a-form-item>
									
								</a-form>
								
							</div>
							
							<!-- 监控 -->
							<div class="wp-form-con" v-if="'monitor'==currentType && item.id==currentId">
								<div class="commonpent_title">监控组件</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="列表样式">
										<a-radio-group v-model:value="item.monitortype">
											<a-radio :value="1">单屏</a-radio>
											<a-radio :value="2">四分屏</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-form-item label="添加监控">
										<template v-for="(val,ind) in item.list" >
											<div class="data_item" :key='ind' v-if="item.monitortype.type == 1 && ind == 0">
												<div class="item-img">
													<img :src="val.url" alt="">
												</div>
												<div class="input-item">
													<a-input v-model:value="val.path">
														<template #addonAfter>
															<com-select-table-list data-type='monitor' :data-index="ind" @getData="getTableData">
																选择链接
															</com-select-table-list>
														</template>
													</a-input>
												</div>
											</div>
											<div class="data_item" :key='ind' v-if="item.monitortype.type == 2">
												<div class="item-img">
													<img :src="val.url" alt="">
												</div>
												<div class="input-item">
													<a-input v-model:value="val.path">
														<template #addonAfter>
															<com-select-table-list data-type='monitor' :data-index="ind" @getData="getTableData">
																选择链接
															</com-select-table-list>
														</template>
													</a-input>
												</div>
											</div>
										</template>
										
										<div v-if="item.monitortype != 1 && item.list.length<= 3" class="add-item" @click="editList(item.id)"><i class="ri-add-line"></i>添加一个</div>
									</a-form-item>
								</a-form>
							</div>
							
							<!-- 侧边导航 -->
							<div v-if="'sidenav'==currentType && item.id==currentId">
								<div class="commonpent_title">侧边导航</div>
								<div style="height: 50px;"></div>
								<a-form ref="form" :label-col="{span:4}" :wrapper-col="{span:16}">
									<a-form-item label="开启">
									    <a-switch v-model:checked="item.sidenavtype"></a-switch>
									</a-form-item>
								</a-form>
							</div>
						</div>
					<!-- </GeminiScrollbar> -->
				</div>
				
			</div>
			
			
		</div>
		<webLink :isShow="linkShow" @surePathSelect="surePathSelect"></webLink>
		
		<!-- 进入提示文字 -->
		<div class="webmask" v-if="tips == 1"></div>
		<div class="webtips" v-if="tips == 1">
			<ul>
				<div class="tips_one">
					<li class="device-monitor"></li>
					<li class="device-mobile">
						<p>点击此按钮进行添加组件操作</p>
						<i class="ri-close-line" @click="webchang(2)"></i>
					</li>
				</div>
			</ul>
		</div>
		
	</div>
</template>

<script>
// 引入拖拽组件
import draggable from 'vuedraggable'
import webLink from '@/components/webassembly/webLink.vue'
import kdColor from '@/components/public/kd-color.vue'
import topbar from '@/components/webassembly/TopBar.vue'
import swiper from '@/components/webassembly/swiper.vue'
import funav from '@/components/webassembly/functioNavigation.vue'
import recomshop from '@/components/webassembly/recommendShop.vue'
import optshop from '@/components/webassembly/optimizationshop.vue'
import land from '@/components/webassembly/landList.vue'
import adopt from '@/components/webassembly/adopt.vue'
import hot from '@/components/webassembly/hotSale.vue'
import product from '@/components/webassembly/product.vue'
import news from '@/components/webassembly/News.vue'
import diyimg from '@/components/webassembly/diyimg.vue'
import textlist from '@/components/webassembly/textList.vue'
import titleText from '@/components/webassembly/titleText.vue'
import monitor from '@/components/webassembly/monitor.vue'
import foter from '@/components/webassembly/foter.vue'
import sidenav from '@/components/webassembly/sidenav.vue'
import comSelectTableList from '@/components/miniapp/com-select-table-list.vue'
import { reactive, toRefs,ref } from 'vue'
import { useRoute } from 'vue-router'
import customPageModel from '@/api/customPage'
import tool from '@/util/tool'
export default{
	components:{
		webLink,
		kdColor,
		draggable,
		topbar,
		webLink,
		swiper,
		funav,
		recomshop,
		optshop,
		land,
		adopt,
		hot,
		product,
		news,
		diyimg,
		textlist,
		titleText,
		monitor,
		foter,
		sidenav,
		comSelectTableList
	},
	setup(){
		const state = reactive({
			is_default: '',
			screenHeight: window.innerHeight,
			activeNames: ['1'],
			list:[],
			currentId: 0,
			currentType: '',
			showWrapper: false,   //模板点击
			basicShow: true,   //右侧详情
			imageVisible:false,  //图片显示
			linkShow: false,
			logo: 'https://kundian.cqkundian.com/farm/1/png/20200725/332cd791575c03ca74d8f03b8d9594341595667786.png',
			code:'https://kundian.cqkundian.com/farm/1/png/20200725/c6ffeb30ecaefffcca4b0369276363d41595667890.png',
			currentListIndex:0,
			moveDataelse: {
				x: null,
				y: null
			},
			tips: 1,
			dataVisible:false,
			foter:{
				accounturl: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
				wechaturl: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
				iosurl: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
				androidurl: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
				codeurl: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
				codetitle: '智慧农场演示',
				fotphone: '187-2323-7733',
				fotdate: '9:00-18:00 (周一至周五)',
				mailbox: 'tukun0206@qq.com',
				record: '重庆坤典科技有限公司 版权所有©2016-2018渝ICP备15033797号-1'
			},
			show_foter: false,
			name:'首页',
			topbarList: {
				website_hander_type: 1,
				website_hander_title: '',
				website_hander_logo: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
			},
		})
		const myRef = ref(null);
		const opt = useRoute().query
		if( opt.id ){
			customPageModel.getPcPageDetail(opt.id,res=>{
				state.id = opt.id
				state.list = res.data.content.data
				state.is_default = res.sign
				state.foter = res.data.footer.foter
				state.show_foter = res.data.footer.show_foter== 1 ? true :false
				state.name = res.name
			})
		}

		function selectCurrentType(e,currentId){
			state.currentId = currentId
			state.list.map((item,index)=>{
				if( item.id == currentId ){
					state.currentType = item.type
					state.showWrapper = true
				}
			})
		}

		// 获取当前页面的高度
		window.onresize = () => {
			return (() => {
				state.screenHeight = window.innerHeight
			})()
		}

		//新增元素信息
		function addElement(type){
			let timeId = new Date().getTime();
			state.list = customPageModel.addElement(state.list,timeId,type)
		}

		function editList(id){
			state.list.map((item,index)=>{
				if( item.id == id ){
					switch (state.currentType){
						case 'topbar':
							state.list[index].list.push({
								title: '导航标题',
								path: ''
							})
							break;
						case 'swiper':
							state.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								path: ''
							})
							break;
						case 'news':
							state.list[index].list.push({
								id:0,
								cover: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								title: '这里是资讯信息标题',
								time: ''
							})
							break;
						case 'diyimg':
							state.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								title: '我是标题',
								path: ''
							})
							break;
						case 'textlist':
							state.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								title: '我是标题',
								cont: '我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容',
								path: '',
								more: false
							})
							break;
						case 'monitor':
							state.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								path: ''
							})
							break;
						default:
							break;
					}
				}
			})
		}

		//删除元素信息
		function showDeleteWrapper(id){
			tool.confirm("确认删除该组件元素吗？").then(()=>{
				state.list.map((item,index)=>{
					if( item.id == id ){
						state.list.splice(index,1)
					}
				})
			}).catch(()=>{})
		}

		function closewrapper(){
			state.showWrapper = false
		}

		//选择链接
		function showLink(ind){
			state.linkShow = true
			state.currentListIndex = ind
		}

		function surePathSelect(path){
			if( path ){
				state.list.map((item,index)=>{
					if( item.id == state.currentId ){
						if( item.list ){
							item.list.map((val,ind)=>{
								if( ind == state.currentListIndex ){
									state.list[index].list[ind].path = path
								}
							})
						}else{
							state.list[index].path = path;
						}
		
					}
				})
				state.linkShow =false
			}else{
				state.linkShow =false
			}
		}

		//图片选择
		function uploadImg(ind){
			state.imageVisible = true;
			state.currentListIndex = ind
		}

		function deleteList(ind,title){
			state.list.map((item,index)=>{
				if( item.id == state.currentId ){
					if( !title ) title = '确认删除吗？'
					tool.confirm(title).then(()=>{
						state.list[index].list.splice(ind,1)
					}).catch(()=>{})
				}
			})
		}

		function getTableData(e){
			let {list,currentType} = state
			list.map((item,index)=>{
				if( item.id == state.currentId ){
					item.list.map((val,ind)=>{
						if( ind == e.index ){
							if( currentType == 'news' ){
								list[index].list[ind].id = e.value.id
								list[index].list[ind].title = e.value.title
								list[index].list[ind].cover = e.value.cover
								list[index].list[ind].time = e.value.time
							}
							if( currentType == 'monitor' ){
								list[index].list[ind].path = e.value.src
								list[index].list[ind].url = e.value.cover
							}
						}
					})
				}
			})
			state.list = list
			state.dataVisible = false
		}

		//保存页面
		function saveHomePage(is_default){
			let { list,id ,show_foter,foter,name} = state
			let data ={
				data:{
					content: {
						data:list,
					},
					footer:{
						show_foter:show_foter? 1 : 0 ,
						foter
					},
				},
				name
			}
			if(is_default == 1) data.sign = 'home'
			if( id ) data.id = id
			customPageModel.setPcPage(data)
		}

		const mouseMoveHandleelse = event =>{
			let moveLeft = event.pageX - state.moveDataelse.x + 'px';
			let moveTop = event.pageY - state.moveDataelse.y + 'px';
			myRef.value.style.left = moveLeft;
			myRef.value.style.top = moveTop;
		}

		// 移动
		function mouseDown(event) {
			console.log('myref', myRef.value.offsetLeft);
			console.log('myref', myRef.value.offsetTop);
			
			state.moveDataelse.x = event.pageX - myRef.value.offsetLeft;
			state.moveDataelse.y = event.pageY - myRef.value.offsetTop;
			window.onmousemove = mouseMoveHandleelse
		}

		function mouseUpHand(){
			window.onmousemove = null;
		}

		function webchang(id){
			state.tips = id
		}

		function changeCurrentItem(){
			state.showWrapper = true
			state.currentId = 0
		}

		
		function changeCurrent(){
			state.showWrapper = true
			state.currentId = -1
		}

		return{
			...toRefs(state),
			selectCurrentType,
			addElement,
			editList,
			showDeleteWrapper,
			closewrapper,
			showLink,
			surePathSelect,
			uploadImg,
			deleteList,
			getTableData,
			saveHomePage,
			mouseDown,
			mouseMoveHandleelse,
			mouseUpHand,
			webchang,
			changeCurrentItem,
			changeCurrent,
			myRef
		}
	}
}
</script>

<style lang="scss">
	body {
	    margin: 0;
	    padding: 0;
	}
	li, ul , p{
	    padding: 0;
	    list-style: none;
	    margin: 0;
	}
	#webPage{
		position: relative;
		width: 99.8%;
		height: 100%;
		// overflow-x: hidden;
		-moz-user-select: none; 
		-webkit-user-select: none; 
		-ms-user-select: none; 
		-khtml-user-select: none; 
		user-select: none;
		
		.webpage-menu-btn{
			display: flex;
			height: 40px;
			background: #1B2C54;
			width: 100%;
			line-height: 40px;
			align-items: center;
			text-align: right;
			justify-content: flex-end;
			position: fixed;
			top: 0;
			z-index: 99;
		}
		
		// 组件列表
		.basic-component{
			width: 290px;
			position: fixed;
			z-index: 2002;
			margin-top: 40px;
			left: 3%;
			background: #fff;
			border-radius: 10px 10px 0 0;
			
			.entryBtnsList {
			    min-width: 54px;
			    position: fixed;
			    z-index: 9;
			    left: 56px;
			    top: 280px;
			    cursor: move;
				
				.funPanelEntryBtn {
				    height: 54px;
				    width: 54px;
				    border-radius: 54px;
				    overflow: hidden;
				    margin-bottom: 15px;
				    background: #fafafa;
				    line-height: 0;
				    cursor: pointer;
				    box-shadow: 2px 3px 7px 0 rgba(0,0,0,.2);
				    transition: background-color,width .2s;
					display: flex;
					align-items: center;
					
					.funPanelEntryBtnSvg {
						width: 40px;
						height: 40px;
						line-height: 40px;
					    font-size: 30px;
					    margin: 7px;
					    fill: #5e616b;
					    color: #dae0f8;
						margin-left: 11px;
					}
					
					.funPanelEntryBtnText {
					    display: none;
					    height: 54px;
					    margin-left: -8px;
					    line-height: 54px;
					    color: #fff;
					    font-size: 15px;
					    font-family: "微软雅黑";
					    white-space: nowrap;
					    vertical-align: top;
					}
				}
				
				.funPanelEntryBtn_module:hover {
				    width: 100px;
				}
				.funPanelEntryBtn:hover {
				    background-color: #5874d8;
				}
				.funPanelEntryBtn:hover .funPanelEntryBtnSvg {
				    fill: rgba(255,255,255,1);
				    color: #fff;
				}
				
				.funPanelEntryBtn_module:hover .funPanelEntryBtnText {
				    display: block;
				}
			}
			
			.assemblylist{
				box-shadow: 0 0 18px 0 #eee;
				height: 600px;
				padding-bottom: 20px;
				
				.basic-component-ul .el-collapse{
					width: 100%;
					padding: 0 10px;
					
					.el-collapse-item__content{
						display: flex;
						margin-top: 10px;
						flex-wrap: wrap;
						margin-left: 10px;
						// justify-content: space-around;
					}
				}
				.basic-component-ul-con{
					display: flex;
					flex-wrap: wrap;
				}
			}
			
			&-title{
				height: 40px;
				line-height: 40px;
				background: #6680E1;
				text-align: center;
				color: #fff;
				border-radius: 10px 10px 0 0;
				display: flex;
				align-items: center;
				padding: 0 18px;
				justify-content: space-between;
				
				>p:last-child{
					cursor: pointer;
					font-size: 20px;
				}
			}
		
			&-ul{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
		
				&-li{
					width:27%;
					height: 70px;
					text-align: center;
					// border: 1px solid #F4F4F4;
					font-size: 12px;
					cursor: pointer;
					margin-right: 20px;
		
					img{
						margin-top: 14px;
						width: 20px;
						height: 20px;
					}
				}
				
				&-li:nth-child(3n){
					margin-right: 0;
				}
				
				&-li:hover{
					box-shadow: 0 0 18px 2px #eee;
					// color: #2E94FE;
				}
			}
		}
		
		.none-element{
			text-align: center;
			font-size: 12px;
			color: gray;
			line-height: 200px;
		}
		.mianWrapper{
		    // width: 375px;
		    width: 100%;
		    border: 1px solid #e5e5e5;
		    position: relative;
		    background: #F8F8F8;
			margin-top: 40px;
		
			.wxHd{
				width: 100%;
				margin: 0px auto;
				height: 22px;
				position: relative;
				background-repeat: no-repeat;
				background-size: 100% 100%;
		
				div{
				    font-size: 16px;
				    text-align: center;
				    width: 60%;
				    line-height: 40px;
				    position: absolute;
				    left: 20%;
				    top: 30px;
				    color: #333;
				}
			}
		}
		
		.eddit_wrapper {
		    min-height: 680px;
			
		    width: 25%;
		    box-sizing: border-box;
		    background: #fff;
		    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			position: fixed;
			top: 80px;
			right: 30px;
			z-index: 1000;
			border-radius: 10px 10px 0 0;
			cursor: move !important;
			
			.scroll-bar{
				height: 670px;
				margin-bottom: 10px;
				position: relative;
				border-radius: 10px 10px 0 0;
				
				
			}
			.pageclose{
				position: absolute;
				right: 10px;
				top: 9px;
				font-size: 20px;
				cursor: pointer;
				z-index: 99;
				color: #fff;
			}
			
		    .banner_container{
		        padding: 0 15px 0px 10px;
		    }
		
			.el-input-group{
				width: 100% !important;
			}
		
		    .el-slider__button-wrapper{
		        height: 25px;
		        width: 25px;
		        top: -10px;
		    }

			.wp-form-con{
				width: 100%;
				height: 680px;
				overflow: hidden;
				overflow-y: auto;
			}
		}
		
		.data_item{
			width: 94%;
			/* height: 90px; */
			border: 1px solid #f4f4f4;
			border-radius: 10px;
			// display: flex;
		    align-items: center;
			padding: 10px 10px;
			cursor: move;
			margin-bottom: 10px;
			position: relative;
		}
		
		.data_item:hover{
			border: 1px dashed #1A97EE;
		}
		
		.data_item .item-img{
			width: 96% !important;
			height: 95px;
			border: 1px solid #f4f4f4;
			margin-right: 10px;
			padding: 5px;
		}
		.item-img img{
			width: 100% !important;
			height: 95px;
		}
		.input-item{
			width: 100%;
			height: 100%;
			margin-top: 10px;
			display: flex;
		}
		
		.input-text .el-textarea__inner{
			height: 100px !important;
		}
		
		.input-item .el-input{
			margin-bottom: 10px;
		}
		.item_close{
			position: absolute;
			top: 0px;
			right: 0px;
			font-size: 20px;
			color: #868686;
			cursor: pointer;
			z-index: 999;
		}
		.item-img .txt{
			height: 20px;
			left: 0;
			right: 0;
			background: rgba(0,0,0,0.2);
			color: #fff;
			text-align: center;
			font-size: 12px;
			line-height: 20px;
			position: relative;
			cursor: pointer;
			top: -29px;
		}
		.add-item{
			width: 100%;
			height: 30px;
			border: 1px solid #f4f4f4;
			text-align: center;
			font-size: 14px;
			line-height: 34px;
			color: #868686;
			cursor: pointer;
		}
		.active_wrapper {
		    width: 100%;
		    // height: 590px;
		    margin: 0 auto 0px;
		    position: relative;
		}
		.active_wrapper::-webkit-scrollbar {display:none}
		
		.active_wrapper_li{
		    position: relative;
		    text-align: center;
		    border: 1px dashed #fff;
		}
		
		// .active_wrapper_li:hover{
		//     border: 1px dashed #409EFF;
		// }
		
		.activeBorder{
		    border: 1px dashed #409EFF;
		}
		.active_wrapper_li:hover{
		    cursor: pointer;
		}
		.active_wrapper_li:hover .deleteicon{
		    display: block
		}
		.footer-menu-btn{
			display: flex;
		    height: 40px;
		    background: #1B2C54;
		    width: 100%;
		    line-height: 40px;
		    align-items: center;
		    text-align: right;
		    justify-content: flex-end;
		}
		
		.commonpent_title{
			width: 100%;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			font-weight: bold;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 9;
			background: #6680E1;
			color: #fff;
			padding-left: 5%;
			border-radius: 10px 10px 0 0;
		}
		//
		.markerstyle_img{
		    width: 100px;
		    height: 100px;
		    border: 2px dashed #eee;
		    text-align: center;
		    cursor: pointer;
		
		    >img{
		        width: 100px;
		        height: 100px;
		    }
		
		    .ri-add-line{
		        font-size: 2rem;
		    }
		
		    >div>p:last-child{
		        margin-top: -30px;
		    }
		}
		
		.markerstyle_tips{
		    margin-top: 10px;
		    color: red;
		}
		
		// 提示
		.webmask{
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 99991;
			background: rgba(0,0,0,.4);
			transition: all .3s;
		}
		
		.webtips{
			position: fixed;
			width: 400px;
			height: 400px;
			top: 255px;
			z-index: 99992;
			
			.device-monitor{
				position: absolute;
				top: 90px;
				left: 50px;
				width: 400px;
				height: 100px;
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url(../../../assets/img/pointer.png);
			}
			.device-mobile{
				position: absolute;
				top: 188px;
				left: 96px;
				width: 280px;
				height: 210px;
				line-height: 210px;
				background-repeat: no-repeat;
				background-position: center center;
				background-image: url(../../../assets/img/questionmark.png);
				
				>p{
					position: absolute;
					top: 5px;
					left: 30px;
					font-size: 16px;
					letter-spacing: 1px;
				}
				
				.ri-close-line{
					position: absolute;
					top: -58px;
					left: 233px;
					font-size: 16px;
					color: #fff;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}
</style>
