<template>
	<div id="Monitor">
		<!-- 样式一 -->
		<div class="monitorlist monitora" v-if="monitorList.monitortype == 1">
			<div>
				<video-player class="vjs-custom-skin" :options="monitor[0]" ></video-player>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(monitorList.id)"></i>
		</div>

		<!-- 样式二 -->
		<div class="monitorlist monitorb" v-if="monitorList.monitortype == 2">
			<div v-for="(item,index) in monitor" :key='index'>
				<video-player class="vjs-custom-skin" :name="index" :options="item" ></video-player>
			</div>

			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(monitorList.id)"></i>
		</div>

	</div>
</template>

<script>
	export default{
		data() {
			return {
				monitor:[]
			}
		},
		props:{
			monitorList:{
				type: Object,
				value:{}
			}
		},
		created() {
			console.log(this.monitorList)
			for (var i = 0; i < this.monitorList.list.length; i++) {
				this.monitor[i] = {
					sources: [{
						withCredentials: false,
						type: "application/x-mpegURL",
						src:this.monitorList.list[i].path
					}],
					controlBar: {
						timeDivider: false,
						durationDisplay: false
					},
					flash: { hls: { withCredentials: false }},
					html5: { hls: { withCredentials: false }},
					poster: this.monitorList.list[i].url,
					notSupportedMessage: '此视频暂无法播放，请稍后再试',
				}

			}
		},
		watch:{
			'monitorList.list':{
				deep: true,
				handler (val){
					for (var i = 0; i < val.length; i++) {
                        console.log(111)
						this.monitor[i] = {
							sources: [{
								withCredentials: false,
								type: "application/x-mpegURL",
								src:val[i].path
							}],
							controlBar: {
								timeDivider: false,
								durationDisplay: false
							},
							flash: { hls: { withCredentials: false }},
							html5: { hls: { withCredentials: false }},
							poster: val[i].url,
							notSupportedMessage: '此视频暂无法播放，请稍后再试',
						}
					}
				}
			}
		},
		methods: {
			showDeleteWrapper(id){
				this.$emit('changeDelet',id)
			}
		},
	}
</script>

<style lang="scss">
	#Monitor{
		width: 100%;
		background: #fff;
		padding: 20px 0;

		.monitorlist{
			position: relative;
		}

		.monitorlist:hover{
			border: 1px dashed #409EFF;
		}

		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: 0px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}

		// 样式一
		.monitora{
			width: 1000px;
			margin: 20px auto;
			height: 600px;

			.vjs-custom-skin > .video-js{
				height: 600px;
			}
		}

		// 样式二
		.monitorb{
			width: 1000px;
			margin: 20px auto;
			height: 600px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			.vjs-custom-skin > .video-js{
				width: 490px;
				height: 280px;
			}

		}

	}
</style>
