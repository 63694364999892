<template>
    <div id="optimizationshop">
		
		<div class="optshop optshopa" v-if="optshoptype == 1">
			<div class="optshop_title">
				<img src="../../assets/img/png/a.png" alt="">
				<p>优选店铺 PRODUCTS</p>
			</div>
			<div class="optimizationshop_list" :style="{height:shopList.length<=3?'345px':'710px'}">
			    <ul>
			        <li class="shoplist_cont" v-for="(item, index) in shopList" :key="index">
			            <img class="shoplist_cont_bg" :src="item.logo" alt="">
			            <div class="shoplist_cont_mask"></div>
			            <div class="shoplist_cont_cont">
			                <div style="text-align: center;margin-bottom: 20px;">
			                   <img class="shoplist_cont_logo" :src="item.logo" alt="" />
			                   <p class="shoplistinformation_title">{{ item.name }}</p>
			                </div>
			
			                <div class="shoplist_information">
			                    <p class="shoplistinformation_addres" :title="item.address"><span class="el-icon-location-outline"></span>{{item.address}}</p>
			                    <p class="shoplistinformation_phone"><span class="el-icon-phone-outline"></span>{{item.mobile}}</p>
			                    <p class="shoplistinformation_tag">
			                        <span class="el-icon-discount"></span>
			                        <span v-if="item.tag == undefined || item.tag.length<= 0">暂无标签</span>
			                        <span v-else><span class="shoplistinformation_tag_cont" v-for="(val,ind) in item.tag" :key='ind'>{{val}}</span></span>
			                    </p>
			                </div>
							<div class="optbtn">
								<p>进店查看</p>
							</div>
			            </div>
			        </li>
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(optshopid)"></i>
			    </ul>
			</div>
		</div>
		
		<div class="optshop optshopb" v-if="optshoptype == 2">
			<!-- 顶部 -->
			<div class="optimizationshop_title">
			    <p>优选店铺</p>
			    <p>
			        查看更多
			        <i class="ri-arrow-right-line"></i>
			    </p>
			</div>
			<!-- 店铺列表 -->
			<div class="optimizationshop_list" :style="{height:shopList.length<=3?'235px':'495px'}">
			    <ul>
			        <li class="shoplist_cont" v-for="(item, index) in shopList" :key="index">
			            <img class="shoplist_cont_bg" :src="item.logo" alt="">
			            <div class="shoplist_cont_mask"></div>
			            <div class="shoplist_cont_cont">
			                <div style="display: flex;align-items: center;">
			                   <img class="shoplist_cont_logo" :src="item.logo" alt="" />
			                   <p class="shoplistinformation_title">{{ item.name }}</p>
			                </div>
			
			                <div class="shoplist_information">
			                    <p class="shoplistinformation_addres" :title="item.address"><span class="el-icon-location-outline"></span>{{item.address}}</p>
			                    <p class="shoplistinformation_phone"><span class="el-icon-phone-outline"></span>{{item.mobile}}</p>
			                    <p class="shoplistinformation_tag">
			                        <span class="el-icon-discount"></span>
			                        <span v-if="item.tag == undefined || item.tag.length<= 0">暂无标签</span>
			                        <span v-else><span class="shoplistinformation_tag_cont" v-for="(val,ind) in item.tag" :key='ind'>{{val}}</span></span>
			                    </p>
			                </div>
			            </div>
			        </li>
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(optshopid)"></i>
			    </ul>
			</div>
		</div>
        
		<div class="optshop optshopc" v-if="optshoptype == 3">
			<!-- 顶部 -->
			<div class="optimizationshop_title">
			    <p>优选店铺</p>
			    <p>
			        查看更多
			        <i class="ri-arrow-right-line"></i>
			    </p>
			</div>
			<!-- 店铺列表 -->
			<div class="optimizationshop_list" :style="{height:shopList.length<=3?'300px':'660px'}">
			    <ul class="">
			        <li class="shoplist_cont" v-for="(item, index) in shopList" :key="index">
						<img :src="item.logo" alt="">
						<div class="shoplist_information">
							<p class="shoplistinformation_name">{{item.name}}</p>
							<p class="shoplistinformation_addres" :title="item.address"><span class="el-icon-location-outline"></span>{{item.address}}</p>
							<p class="shoplistinformation_phone"><span class="el-icon-phone-outline"></span>{{item.mobile}}</p>
							<p class="shoplistinformation_tag">
								<span class="el-icon-discount"></span>
								<span v-if="item.tag == undefined || item.tag.length<= 0">暂无标签</span>
								<span v-else><span class="shoplistinformation_tag_cont" v-for="(val,ind) in item.tag" :key='ind'>{{val}}</span></span>
							</p>
							<p class="shoplistinformation_more">了解更多<span class="ri-arrow-right-line"></span></p>
						</div>
			        </li>
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(optshopid)"></i>
			    </ul>
			</div>
		</div>
		
    </div>
</template>

<script>
import { $post } from '@/api/http'
export default {
    name: 'optimizationshop',
    data() {
        return {
            shopList: ''
        };
    },
	props:{
		optshopid:{
			type: Number
		},
		optshoptype:{
			type: Number
		},
		rows:{
			type: String
		}
	},
    created() {
        this.getShopList(6);
    },
	watch:{
		'rows':function(val){
			if(val >= 6){
				this.getShopList(6)
			}else{
				this.getShopList(val)
			}
		}
	},
    methods: {
        getShopList(limit) {
            let t = this;
            $post('getShopList', {limit:limit}).then(res => {
                t.shopList = res.data;
            }).catch(() => {});
        },
		showDeleteWrapper(id){
			this.$emit('changeDelet',id)
		},
    }
};
</script>

<style lang="scss">
#optimizationshop {
    width: 1200px;
    margin: auto;
    padding: 20px 0 0;
	
	.optshop{
		position: relative;
		padding: 20px;
	}
	
	// 样式一
	
	.optshopa{
		// width: 100%;
		height: 100%;
		background: #fff;
		
		.optshop_title{
			position: relative;
			text-align: center;	
			
			>img{
				width: 180px;
				height: 45px;
				margin: 20px 0 0;
			}
			
			>p{
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -70px;
				font-size: 13px;
				color: #67C23A;
				letter-spacing: 1px;
			}
		}
		
		.optimizationshop_list {
		    // background: #fff;
		    padding: 20px 0px 0;
		    // margin-top: 20px;
		}
		
		.optshop:hover{
			border: 1px dashed #409EFF;
		}
		
		.optimizationshop_list ul {
		   display: flex;
		    // justify-content: space-between;
		    flex-wrap: wrap;
		    align-items: center;
		}
		
		.shoplist_cont {
		    width: 28%;
		    height: 325px;
		    display: flex;
		    flex-wrap: wrap;
		    margin-bottom: 20px;
		    cursor: pointer;
		    transition: 0.3s box-shadow, 0.3s transform;
		    margin-right: 92px;
		    position: relative;
		    overflow: hidden;
		
		
		    &_bg{
		        width: 100%;
		        height: 100%;
		        filter: blur(18px);
		    }
		
		    &_mask{
		        position: absolute;
		        width: 100%;
		        height: 100%;
		        top: 0;
		        left: 0;
		        background: rgba(0,0,0,.6);
		    }
		
		    &_cont{
		        position: absolute;
				width: 100%;
				margin-top: 15px;
		    }
		}
		
		.shoplist_cont:nth-child(3n){
		    margin-right: 0;
		}
		
		.shoplist_cont .shoplist_cont_logo {
		    width: 65px;
		    height: 65px;
		    border-radius: 50%;
		    margin: 10px 0 10px 10px;
		}
		
		.shoplist_information {
		    margin-left: 20px;
		    color: #fff;
		}
		
		.optbtn{
			width: 140px;
			height: 40px;
			line-height: 40px;
			margin: 30px auto;
			border-radius: 30px;
			color: #fff;
			background: #67C23A;
		}
		
		.shoplist_cont .shoplistinformation_title {
		    width: 19rem;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		    font-size: 15px;
		    color: #fff;
		    margin-left: 10px;
			text-align: center;
		}
		
		.shoplistinformation_addres{
		    font-size: 12px;
		    color: #fff;
		    width: 19rem;
		    text-overflow: ellipsis;
		    overflow: hidden;
		    white-space: nowrap;
		    letter-spacing: 1px;
			margin-left: -12px;
		}
		
		.shoplistinformation_addres>span{
		    font-size: 18px;
		    color: #fff;
		    margin-right: 20px;
		}
		
		.shoplistinformation_phone{
		    margin-top: 10px;
		    font-size: 14px;
			text-align: left;
		
		    .el-icon-phone-outline{
		        color: #fff;
		        font-size: 18px;
		        margin-right: 20px;
		    }
		}
		
		.shoplistinformation_tag{
		    margin-top: 10px;
		    font-size: 14px;
		    display: flex;
		
		    .el-icon-discount{
		        color: #fff;
		        font-size: 18px;
		        margin-right: 20px;
		        // transform: rotate(45deg);
		    }
		
		    &_cont{
		        display: inline-block;
		        height: 25px;
		        padding: 0 10px;
		        line-height: 25px;
		        font-size: 12px;
		        color: #fff;
		        border-radius: 40px;
		        box-sizing: border-box;
		        white-space: nowrap;
		        border: 1px solid #eee;
		        margin:0 10px 10px 0;
		    }
		}
		
		.shoplist_cont:hover {
		    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
		    transform: translate3d(0, -2px, 0);
		}
	}
	
	// 样式二
	.optshopb{
		background: #fff;
		.optimizationshop_title {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		    font-size: 18px;
		}
		
		.optimizationshop_title > p:first-child {
		    border-left: 3px solid #67c23a;
		    padding-left: 10px;
		}
		
		.optimizationshop_title > p:last-child {
		    font-size: 15px;
		    cursor: pointer;
		}
		
		.optimizationshop_title > p:last-child:hover {
		    color: #67c23a;
		}
		
		/* 店铺列表 */
		.optimizationshop_list {
		    // background: #fff;
		    padding: 20px 0px 0;
		    // margin-top: 20px;
		}
		
		.optshop:hover{
			border: 1px dashed #409EFF;
		}
		
		.optimizationshop_list ul {
		   display: flex;
		    // justify-content: space-between;
		    flex-wrap: wrap;
		    align-items: center;
		}
		
		.shoplist_cont {
		    width: 28%;
		    height: 225px;
		    display: flex;
		    flex-wrap: wrap;
		    margin-bottom: 20px;
		    cursor: pointer;
		    transition: 0.3s box-shadow, 0.3s transform;
		    margin-right: 92px;
		    position: relative;
		    overflow: hidden;
		
		
		    &_bg{
		        width: 100%;
		        height: 100%;
		        filter: blur(18px);
		    }
		
		    &_mask{
		        position: absolute;
		        width: 100%;
		        height: 100%;
		        top: 0;
		        left: 0;
		        background: rgba(0,0,0,.6);
		    }
		
		    &_cont{
		        position: absolute;
		    }
		}
		
		.shoplist_cont:nth-child(3){
		    margin-right: 0;
		}
		
		.shoplist_cont:nth-child(6){
		    margin-right: 0;
		}
		
		.shoplist_cont .shoplist_cont_logo {
		    width: 65px;
		    height: 65px;
		    border-radius: 50%;
		    margin: 10px 0 10px 10px;
		}
		
		.shoplist_information {
		    margin-left: 20px;
		    color: #fff;
		}
		
		.shoplist_cont .shoplistinformation_title {
		    width: 16rem;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		    font-size: 15px;
		    color: #fff;
		    margin-left: 10px;
			text-align: left;
		}
		
		.shoplistinformation_addres{
		    font-size: 12px;
		    color: #fff;
		    width: 19rem;
		    text-overflow: ellipsis;
		    overflow: hidden;
		    white-space: nowrap;
		    letter-spacing: 1px;
			margin-left: -12px;
		}
		
		.shoplistinformation_addres>span{
		    font-size: 18px;
		    color: #fff;
		    margin-right: 20px;
		}
		
		.shoplistinformation_phone{
		    margin-top: 10px;
		    font-size: 14px;
			text-align: left;
		
		    .el-icon-phone-outline{
		        color: #fff;
		        font-size: 18px;
		        margin-right: 20px;
		    }
		}
		
		.shoplistinformation_tag{
		    margin-top: 10px;
		    font-size: 14px;
		    display: flex;
		
		    .el-icon-discount{
		        color: #fff;
		        font-size: 18px;
		        margin-right: 20px;
		        // transform: rotate(45deg);
		    }
		
		    &_cont{
		        display: inline-block;
		        height: 25px;
		        padding: 0 10px;
		        line-height: 25px;
		        font-size: 12px;
		        color: #fff;
		        border-radius: 40px;
		        box-sizing: border-box;
		        white-space: nowrap;
		        border: 1px solid #eee;
		        margin:0 10px 10px 0;
		    }
		}
		
		.shoplist_cont:hover {
		    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
		    transform: translate3d(0, -2px, 0);
		}
	}
    
	// 样式三
	.optshopc{
		background: #fff;
		padding: 20px;
		
		.optimizationshop_title {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		    font-size: 18px;
		}
		
		.optimizationshop_title > p:first-child {
		    border-left: 3px solid #67c23a;
		    padding-left: 10px;
		}
		
		.optimizationshop_title > p:last-child {
		    font-size: 15px;
		    cursor: pointer;
		}
		
		.optimizationshop_title > p:last-child:hover {
		    color: #67c23a;
		}
		
		/* 店铺列表 */
		.optimizationshop_list {
		    // background: #fff;
		    padding: 20px 0px 0;
		    // margin-top: 20px;
		}
		
		.optimizationshop_list ul {
		   display: flex;
		    flex-wrap: wrap;
		    align-items: center;
			
			>li{
				width: 27%;
				height: 305px;
				margin-bottom: 20px;
				cursor: pointer;
				transition: 0.3s box-shadow, 0.3s transform;
				margin-right: 75px;
				position: relative;
						
				>img{
				    width: 80px;
				    height: 80px;
					border-radius: 10px;
					// margin-left: 55px;
				}
				
				.shoplist_information{
					width: 100%;
					height: 255px;
					margin-top: -40px;
					background: #fff;
					border-radius: 30px;
					box-shadow: 0 0px 20px 0 #eee;
					overflow: hidden;
					text-align: left;
					padding: 0 30px;
					line-height: 30px;
					font-size: 13px;
					
					.shoplistinformation_name{
						margin-top: 75px;
					}
					
					.shoplistinformation_more{
						position: absolute;
						right: 30px;
						bottom: 10px;
						
						>span{
							margin-left: 10px;
						}
					}
				}
			}
			
			>li:nth-child(3n){
				margin-right: 0;
			}
		}
		
		
	}
	
	.optshop:hover{
		border: 1px dashed #409EFF;
	}
	
	.deleteicon{
		position: absolute;
		bottom: 0px;
		right: -15px;
		z-index: 999;
		display: none;
		cursor: pointer;
		color: #409EFF;
		font-size: 25px;
	}
}
</style>
