<template>
	<div id="DIYimg">
		<!-- 样式一 -->
		<div class="diy_img diyimg_indexa" v-if="diyimgList.diyimgtype == 1">
			<div class="img_photo">
				<img v-for="(item,index) in diyimgList.list" :key='index' :src="item.url" alt="">
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(diyimgList.id)"></i>
		</div>
		
		<!-- 样式二 -->
		<div class="diy_img diyimg_indexb" v-if="diyimgList.diyimgtype == 2">
			<div class="img_photo">
				<p v-for="(item,index) in diyimgList.list" :key='index'>
					<img :src="item.url" alt="">
					<span>{{item.title}}</span>
				</p>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(diyimgList.id)"></i>
		</div>
		
		<!-- 样式三 -->
		<div class="diy_img diyimg_indexc" v-if="diyimgList.diyimgtype == 3">
			<a-carousel height="400px">
				<div v-for="(item,index) in diyimgList.list" :key='index'>
					<img style="width: 100%;height: 100%;" :src="item.url" alt="">
				</div>
			</a-carousel>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(diyimgList.id)"></i>
		</div>
		
		<!-- 样式四 -->
		<div class="diy_img diyimg_indexd" v-if="diyimgList.diyimgtype == 4">
			<div class="imglsit">
				<img v-for="(item,index) in diyimgList.list" :key='index' :src="item.url" alt="">
				<p></p>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(diyimgList.id)"></i>
		</div>
		
	</div>
</template>

<script>
	export default{
		data() {
			return {
				
			}
		},
		props:{
			diyimgList:{
				type: Object,
				value:{}
			}
		},
		methods: {
			showDeleteWrapper(id){
				this.$emit('changeDelet',id)
			}
		},
	}
</script>

<style lang="scss">
	#DIYimg{
		width: 100%;
		background: #fff;
		padding: 20px 0;
		
		.diy_img{
			position: relative;
		}
		
		.diy_img:hover{
			border: 1px dashed #409EFF;
		}
		
		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: 45px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}
		
		// 样式一
		.diyimg_indexa{
			width: 1000px;
			margin: 20px auto;
			
			.img_photo{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				
				>img:nth-child(1){
					width: 32.5%;
					height: 150px;
				}
				>img:nth-child(2){
					width: 32.5%;
					height: 150px;
				}
				>img:nth-child(3){
					width: 32.5%;
					height: 150px;
				}
				>img:nth-child(4){
					width: 66.2%;
					height: 220px;
					margin-top: 10px;
				}
				>img:nth-child(5){
					width: 32.5%;
					height: 220px;
					margin-top: 10px;
				}
			}
		}
		
		// 样式二
		.diyimg_indexb{
			width: 96%;
			margin: 20px auto;
			
			.img_photo{
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				
				>p{
					width: 24%;
					height: 195px;
					margin-bottom: 20px;
					text-align: center;
					
					img{
						width: 100%;
						height: 100%;
					}
					
					span{
						font-size: 14px;
						font-weight: bold;
					}
				}
			}
		}
		
		// 样式三
		.diyimg_indexc{
			height: 400px;
			
			.el-carousel__indicators{
				display: none;
			}
		}
		
		// 样式四
		.diyimg_indexd{
			width: 1000px;
			margin: 20px auto;
			
			.imglsit{
				display: flex;
				justify-content: space-around;
				align-items: center;
				position: relative;
				padding: 0 10px;
				
				>img{
					width: 23%;
					height: 275px;
					z-index: 11;
					background: #fff;
				}
				
				>p{
					width: 100%;
					height: 100px;
					background: #F2E6D8;
					position: absolute;
					z-index: 1;
				}
			}
		}
		
	}
</style>
