<template>
	<div id="Product">
		<div class="recommend_index recomshop state0" v-if="productList.productype == 1">
		    <div class="recommend_title_index"><p>新品上架</p></div>
		    <div class="carousel_mall">
		        <ul class="carousel_cont">
		            <li v-for="(item, index) in shoplist" :key="index">
		                <img class="carousel_img" :src="item.cover" />
		                <p class="carousel_title" :title="item.goods_name" :style="tiColor">{{ item.goods_name }}</p>
		                <div class="carousel_polic">
		                    <p :style="priceC">¥{{ item.price }}元</p>
		                    <p class="carbuynow" v-if="productList.salesvolume == true">销量：{{item.sale_count}}</p>
		                </div>
		            </li>
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(productList.id)"></i>
		        </ul>
		    </div>
		</div>
		
		<div class="recommend_index recomshopb state0" v-if="productList.productype == 2">
		    <div class="recommend_title_index"><p>新品上架</p></div>
		    <div class="carousel_mall">
		        <ul class="carousel_cont">
		            <li v-for="(item, index) in shoplist" :key="index">
		                <img class="carousel_img" :src="item.cover" />
		                <p class="carousel_title" :title="item.goods_name" :style="tiColor">{{ item.goods_name }}</p>
		                <div class="carousel_polic">
		                    <p :style="priceC">¥{{ item.price }}元</p>
		                    <p class="carbuynow">立即购买</p>
		                </div>
		            </li>
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(productList.id)"></i>
		        </ul>
		    </div>
		</div>
		
		<div class="recommend_index recomshopc state0" v-if="productList.productype == 3">
		    <div class="recommend_title_index"><p>新品上架</p></div>
		    <div class="carousel_mall">
		        <ul class="carouselcont">
		            <li v-for="(item, index) in shoplist" :key="index">
		                <img class="carousel_img" :src="item.cover" />
		                <p class="carousel_title" :title="item.goods_name" :style="tiColor">{{ item.goods_name }}</p>
		                <div class="carousel_polic">
		                    <p class="carprice" :style="priceC">¥{{ item.price }}元</p>
		                    <p class="carbuynow" v-if="productList.salesvolume == true">销量：{{item.sale_count}}</p>
		                </div>
		            </li>
					<img class="carousimg" :src="productList.url" alt="">
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(productList.id)"></i>
		        </ul>
		    </div>
		</div>
		
		<!-- 样式四 -->
		<div class="recommend_index carousfor state0" v-if="productList.productype == 4">
			<div class="carousfor_title">
				<img src="../../assets/img/png/a.png" alt="">
				<p>新品上架 PRODUCT</p>
			</div>
			<div class="carousel_mall">
				<ul class="carouselcont">
				    <li v-for="(item, index) in shoplist" :key="index">
				        <img class="carousel_img" :src="item.cover" />
				        <div class="carousel_polic">
							<p class="carousel_title" :title="item.goods_name" :style="tiColor">{{ item.goods_name }}</p>
				            <p class="carprice" :style="priceC">¥{{ item.price }}元</p>
				        </div>
				    </li>
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(productList.id)"></i>
				</ul>
			</div>
		</div>
		
	</div>
</template>

<script>
import { $post } from '@/api/http'
export default{
	data() {
		return {
			shoplist:[]
		}
	},
	props:{
		productList:{
			type:Object,
			value:{}
		}
	},
	created() {
		this.getStoreGoodsList(10)
	},
	computed:{
		tiColor(){
			return `color: ${this.productList.titleColor}`
		},
		priceC(){
			return `color: ${this.productList.priceColor}`
		}
	},
	watch:{
		'productList.productype': function (val, oldVal) {
			if( val == 1 || val == 2){
				this.getStoreGoodsList(10)
			}
			if( val == 3){
				this.getStoreGoodsList(8)
			}
			if( val == 4){
				this.getStoreGoodsList(10)
			}
		},
		'productList.rows': function (val, oldVal) {
			this.getStoreGoodsList(val)
		}
		
	},
	mounted() {
		
	},
	methods: {
		getStoreGoodsList(limit){
			$post('getStoreGoodsList',{limit,is_new: 1},true,3).then(res =>{
				this.shoplist = res.data
			}).catch(err =>{console.log(err)})
		},
		
		showDeleteWrapper(id){
			this.$emit('changeDelet',id)
		},
	},
}
</script>

<style lang="scss">
	#Product{
		width: 1200px;
		margin: auto;
		
		// 样式一
		.recomshop{
			background: #fff;
			margin-top: 20px;
			position: relative;
			
			.recommend_title_index{
				text-align: left;
				font-size: 18px;
				border-bottom: 1px solid #eee;
				padding: 20px;
				
				>p{
					border-left: 3px solid #FF5500;
					padding-left: 20px;
				}
			}
			
			.carousel_cont {
			    display: flex;
			    align-items: center;
			    flex-wrap: wrap;
				// padding: 0 20px;
			}
			
			.carousel_cont>li {
			    padding: 20px 5px;
			    width: 239px;
			    height: 280px;
				border-right: 1px solid #eee;
				border-bottom: 1px solid #eee;
			    background: #fff;
			    cursor: pointer;
				padding: 10px 15px;
				box-sizing: border-box;
			}
			
			.carousel_cont>li:nth-child(5n){
				border-right: none;
			}
			
			.carousel_img {
			    width: 100%;
			    height: 195px;
			}
			
			.carousel_title {
			    width: 17rem;
			    padding: 10px 5px 5px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    font-size: 13px;
				text-align: left;
			}
			
			.carousel_polic {
			    display: flex;
			    padding: 0 10px 10px 10px;
			    justify-content: space-between;
			    align-items: center;
			    font-size: 15px;
			}
			
			.carousel_polic .carbuynow {
			    padding: 6px;
			    border-radius: 5px;
			    font-size: 13px;
			}
			
			.deleteicon{
				position: absolute;
				bottom: 0px;
				right: -15px;
				z-index: 999;
				display: none;
				cursor: pointer;
				color: #409EFF;
				font-size: 25px;
			}
		}
		
		// 样式二
		.recomshopb{
			padding: 20px 0 20px;
			.recommend_title_index {
			    text-align: left; 
			    font-size: 18px;
			    border-left: 3px solid #67c23a;
			    padding-left: 10px;
			}
			
			.carousel_cont {
			    display: flex;
			    align-items: center;
			    flex-wrap: wrap;
			}
			
			.carousel_cont>li {
			    margin: 20px 0px 0;
			    width: 220px;
			    height: 280px;
			    border: 1px solid #ECECEC;
			    border-radius: 0 0 10px 10px;
			    margin-right: 22px;
			    background: #fff;
			    cursor: pointer;
			}
			
			.carousel_cont>li:nth-child(5n){
			    margin-right: 0;
			}
			
			.carousel_img {
			    width: 100%;
			    height: 195px;
			}
			
			.carousel_title {
			    width: 17rem;
			    padding: 10px 5px 5px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    font-size: 13px;
				text-align: left;
			}
			
			.carousel_polic {
			    display: flex;
			    padding: 0 10px 10px 10px;
			    justify-content: space-between;
			    align-items: center;
			    font-size: 15px;
			}
			
			.carousel_polic .carbuynow {
			    padding: 6px;
			    border: 1px solid #ECECEC;
			    border-radius: 5px;
			    font-size: 13px;
			}
			
			.carousel_polic .carbuynow:hover {
			    background: #67c23a;
			    color: #fff;
			}
		}
		
		.recommend_index {
			position: relative;
		}
		.recommend_index:hover{
			border: 1px dashed #409EFF;
		}
		
		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: -15px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}
		
		// 样式三
		.recomshopc{
			background: #fff;
			padding: 20px 0;
			position: relative;
			margin-top: 10px;
			
			.recommend_title_index{
				text-align: left;
				font-size: 18px;
				border-left: 3px solid #67c23a;
				padding-left: 10px;
				margin-left: 20px;
			}
			.carouselcont {
			    display: flex;
			    align-items: center;
			    flex-wrap: wrap;
				padding: 0 20px;
			}
			
			.carouselcont>li {
			    margin: 20px 0px 0;
			    width: 220px;
			    height: 280px;
			    border: 1px solid #ECECEC;
			    border-radius: 0 0 10px 10px;
			    margin-right: 20px;
			    background: #fff;
			    cursor: pointer;
			}
			
			.carouselcont>li:nth-child(4n){
			    margin-right: 0;
			}
			
			.carousimg{
				position: absolute;
				right: 20px;
				top: 65px;
				width: 190px;
				height: 582px;
			}
			
			.carousel_img {
			    width: 100%;
			    height: 195px;
			}
			
			.carousel_title {
			    width: 17rem;
			    padding: 10px 5px 5px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    font-size: 13px;
				text-align: left;
			}
			
			.carousel_polic {
			    display: flex;
			    padding: 0 10px 10px 10px;
			    justify-content: space-between;
			    align-items: center;
			    font-size: 15px;
				margin-top: 6px;
			}
			
			.carousel_polic .carbuynow {
				padding: 0;
				border: none;
			    font-size: 13px;
			}
		}
		
		// 样式四
		.carousfor{
			background: #fff;
			margin-top: 20px;
			position: relative;
			padding-bottom: 20px;
			
			&_title{
				text-align: center;
				position: relative;
				
				>img{
					width: 180px;
					height: 45px;
					margin: 20px 0;
				}
				
				>p{
					position: absolute;
					top: 38%;
					left: 50%;
					margin-left: -63px;
					font-size: 13px;
					color: #67C23A;
					letter-spacing: 1px;
				}
			}
			
			.carouselcont {
			    display: flex;
			    align-items: center;
			    flex-wrap: wrap;
				padding: 0 20px;
				
				>li {
				    margin: 20px 0px 0;
				    width: 215px;
				    height: 235px;
				    // border-radius: 0 0 10px 10px;
				    margin-right: 20px;
				    background: #fff;
				    cursor: pointer;
					box-sizing: border-box;
					position: relative;
					border: 1px solid #eee;
					
					img{
						width: 100%;
						height: 100%;
					}
					
					.carousel_polic{
						position: absolute;
						bottom: 10%;
						left: 0;
						background: rgba(255,255,255,.8);
						height: 40px;
						width: 100%;
						
						.carousel_title{
							width: 13rem;
							padding: 5px 5px 0 5px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-size: 13px;
							text-align: center;
						}
						
						.carprice{
							font-size: 13px;
						}
					}
				}
				
				>li:nth-child(5n){
				    margin-right: 0;
				}
				
			}
		}
		
	}
</style>
