<template>
	<div id="text-list">
		<!-- 样式一 -->
		<div class="textlist texta" v-if="textList.textype == 1">
			<div class="texta_list" v-for="(item,index) in textList.list" :key='index'>
				<p class="text_title">{{item.title}}</p>
				<p v-html="item.cont" class="text_cont"></p>
				<img :src="item.url" alt="">
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(textList.id)"></i>
		</div>
		
		<!-- 样式二 -->
		<div class="textlist textb" v-if="textList.textype == 2">
			<div class="textb_list" v-for="(item,index) in textList.list" :key='index'>
				<img :src="item.url" alt="">
				<div class="textb_cont">
					<p class="txtb_title">{{item.title}}</p>
					<p v-html="item.cont" class="txtb_cont"></p>
					<p class="txtb_more" v-if="item.more">更多</p>
				</div>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(textList.id)"></i>
		</div>
		
		<!-- 样式三 -->
		<!-- <div class="textlist textc" v-if="textList.textype == 3">
			11111111
		</div> -->
		
	</div>
</template>

<script>
	export default{
		data() {
			return {
				
			}
		},
		props:{
			textList:{
				type: Object,
				value:{}
			}
		},
		methods: {
			showDeleteWrapper(id){
				this.$emit('changeDelet',id)
			}
		},
	}
</script>

<style lang="scss">
	#text-list{
		width: 100%;
		background: #fff;
		
		.textlist{
			position: relative;
		}
		.textlist:hover{
			border: 1px dashed #409EFF;
		}
		
		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: 0px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}
		
		// 样式一
		.texta{
			width: 1100px;
			margin: 10px auto;
			padding: 20px 0;
			
			&_list{
				margin-bottom: 20px;
				
				.text_title{
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 2px;
					padding-bottom: 20px;
				}
				
				.text_cont{
					text-align: left;
					font-size: 13px;
					letter-spacing: 1px;
					line-height: 26px;
					margin-bottom: 25px;
				}
				
				>img{
					width: 100%;
					height: 300px;
				}
			}
		}
		
		// 样式二
		.textb{
			width: 1100px;
			margin: 0px auto;
			padding: 20px 0;
			
			&_list{
				margin-bottom: 20px;
				display: flex;
				justify-content: space-between;
				
				>img{
					width: 40%;
					margin-right: 20px;
					height: 250px;
				}
			}
			
			&_cont{
				width: 57%;
				text-align: left;
				
				.txtb_title{
					margin-top: 20px;
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 2px;
				}
				
				.txtb_cont{
					height: 180px;
					margin-top: 10px;
					font-size: 13px;
					letter-spacing: 1px;
					line-height: 23px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 8;
					-webkit-box-orient: vertical;
				}
				
				.txtb_more{
					color: #7dccbb;
				}
			}
		}
		
		
	}
</style>
