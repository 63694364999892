<template>
	<div id="News">
		<!-- 样式一 -->
		<div class="newslist newsa">
			<div class="news_index">
				<img :src="newsList.url" alt="">
				<p></p>
			</div>
			<div class="news_title">
				<p>新闻资讯</p>
				<p>NEWS CENTER</p>
			</div>
			<div class="news_cont">
				<div class="news_cont_lf">
					<div class="lf_title">公司新闻</div>
					<div class="lf_cont">
						<div class="contlist" v-for="(item,index) in newsList.list" :key='index'>
							<div class="contlist_lf">
								<p>{{item.day}}</p>
								<p>{{item.year}}-{{item.month}}</p>
							</div>
							<div class="contlist_rt">
								<p>{{item.title}}</p>
								<!-- <p v-html='item.content'></p> -->
							</div>
						</div>
					</div>
				</div>
				<div class="news_cont_rt">
					<el-carousel height="400" arrow="never" :interval='interval'>
						<el-carousel-item>
							<img :src="newsList.rightimg" alt="">
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(newsList.id)"></i>
		</div>
		
		<!-- 样式二 -->
		<!-- <div class="newslist newsb"  v-if="newsList.newstype ==2">
			<div class="newsb_title">
				<div class="title_lf">
					<p>新闻资讯</p>
					<p>News center</p>
				</div>
				<div class="title_rt">查看更多<span class="el-icon-arrow-right"></span></div>
			</div>
			<div class="newsb_cont">
				<div class="cont_swiper">
					<el-carousel height="400" arrow="never" :interval='interval'>
						<el-carousel-item v-for="(item,index) in newsList.list" :key="index">
							<img :src="item.url" alt="">
						</el-carousel-item>
					</el-carousel>
					<div class="swiper_cont">
						<div class="cont_lf">
							<p>{{item.day}}</p>
							<p>{{item.year}}-{{item.month}}</p>
						</div>
						<div class="cont_rt">
							<p>{{item.title}}</p>
							<p v-html='item.content'></p>
						</div>
					</div>
				</div>
				<div class="cont_collapse">
					<el-collapse v-model="activeName" accordion>
						<el-collapse-item :title="item.title" :name="(index + 1)+''" v-for="(item,index) in list" :key='index'>
							<div class="collapse_cont" v-html="item.content"></div>
						</el-collapse-item>
					</el-collapse>
				</div>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(newsList.id)"></i>
		</div> -->
	</div>
</template>

<script>
	export default{
		data() {
			return {
				list: [],
				activeName: '1',
				interval: 5000
			}
		},
		props:{
			newsList:{
				type: Object,
				value:{}
			}
		},
		watch:{
			
		},
		created() {
			let t = this
			t.newsList.list.map((item,index) =>{
				let d = item.time
				let c = d.split(' ')
				let b = c[0].split('-')
				t.newsList.list[index].year = b[0]
				t.newsList.list[index].month = b[1]
				t.newsList.list[index].day = b[2]
				// t.$set(t.newsList.list[index],'year',b[0])
				// t.$set(t.newsList.list[index],'month',b[1])
				// t.$set(t.newsList.list[index],'day',b[2])
			})
		},
		methods: {
			showDeleteWrapper(id){
				this.$emit('changeDelet',id)
			}
		},
	}
</script>

<style lang="scss">
	#News{
		width: 100%;
		height: 100%;
		// overflow: hidden;
		
		.newslist{
			position: relative;
			background: #fff;
		}
		
		.newslist:hover{
			border: 1px dashed #409EFF;
		}
		
		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: 45px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}
		
		// 样式一
		.newsa{
			width: 100%;
			padding-bottom: 30px;
			
			.news_index{
				width: 100%;
				height: 500px;
				box-sizing: border-box;
				position: relative;
				
				img{
					width: 100%;
					height: 100%;
				}
				
				>p{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 500px;
					background: rgba(255,255,255,.7);
				}
			}
			
			.news_title{
				position: absolute;
				top: 4%;
				left: 50%;
				margin-left: -65px;
				
				>p:first-child{
					font-size: 20px;
					letter-spacing: 1px;
					font-weight: bold;
					margin-bottom: 20px;
				}
				
				>p:last-child{
					color: #999;
					font-size: 20px;
					letter-spacing: 2px;
				}
			}
			
			.news_cont{
				display: flex;
				width: 820px;
				height: 400px;
				margin: auto;
				position: absolute;
				left: 50%;
				top: 23.5%;
				margin-left: -410px;
				background: #fff;
				box-shadow: 0 0 20px 0 #eee;
				
				&_lf{
					width: 100%;
					
					.lf_title{
						width: 100%;
						height: 45px;
						line-height: 45px;
						background: #DFB330;
						color: #fff;
						text-align: center;
						font-size: 16px;
						letter-spacing: 1px;
					}
					
					.lf_cont{
						padding: 15px 25px;
						text-align: left;
						
						.contlist{
							display: flex;
							align-items: center;
							padding: 14px 0;
							border-bottom: 1px solid #eee;
							
							.contlist_lf{
								width: 90px;
								height: 55px;
								background: #DFB330;
								color: #fff;
								text-align: center;
								margin-right: 15px;
								
								>p:first-child{
									margin-top: 10px;
									font-size: 16px;
								}
							}
							.contlist_rt{
								font-size: 14px;
								
								>p:first-child{
									width: 15rem;
									overflow:hidden;
									text-overflow:ellipsis;
									display:-webkit-box;
									-webkit-line-clamp:2;
									-webkit-box-orient:vertical;
								}
								
								>p:last-child{
									font-size: 12px;
									color: #999;
									margin-top: 4px;
									width: 339px;
									height: 32px;
									overflow: hidden;
									
									img{
										// display: none;
									}
								}
							}
						}
						
						.contlist:last-child{
							border-bottom: none;
						}
						
					}
					
				}
				
				&_rt{
					width: 70%;
					
					img{
						width: 100%;
						height: 100%;
					}
					
					.el-carousel__indicators{
						display: none;
					}
					
					.el-carousel__container{
						height: 400px;
					}
				}
			}
			
			
			
		}
		
		// 样式二
		.newsb{
			width: 100%;
			background: #fff;
			
			&_title{
				display: flex;
				width: 1000px;
				margin: 20px auto;
				justify-content: space-between;
				align-items: center;
				
				.title_lf{
					display: flex;
					align-items: center;
					
					>p:first-child{
						margin-right: 15px;
						font-size: 18px;
						letter-spacing: 2px;
					}
					
					>p:last-child{
						color: #999;
						letter-spacing: 1px;
					}
				}
				
				.title_rt{
					font-size: 14px;
					color: #999;
					letter-spacing: 1px;
				}
			}
			
			&_cont{
				width: 1000px;
				margin: auto;
				padding: 10px 0 20px;
				display: flex;
				
				.cont_swiper{
					width: 40%;
					margin-right: 30px;
					position: relative;
					margin-bottom: 40px;
					
					.swiper_cont{
						position: absolute;
						bottom: -40px;
						left: 0;
						width: 93%;
						height: 70px;
						background: #4A86FF;
						color: #fff;
						z-index: 99;
						display: flex;
						padding: 14px;
						align-items: center;
						
						.cont_lf{
							width: 17%;
							text-align: center;
							margin-right: 10px;
							line-height: 20px;
							
							>p:first-child{
								font-size: 20px;
							}
						}
						
						.cont_rt{
							width: 80%;
							font-size: 14px;
							text-align: left;
							
							>p:first-child{
								width: 15rem;
								overflow:hidden;
								text-overflow:ellipsis;
								white-space:nowrap;
								padding-bottom: 8px;
							}
							
							>p:last-child{
								font-size: 12px;
								width: 290px;
								height: 32px;
								overflow: hidden;
								border-top: 1px solid #6B9CFF;
								padding-top: 8px;
								
								span{
									background: #4A86FF !important;
									color: #fff !important;
								}
							}
							
						}
					}
				}
				
				.cont_collapse{
					width: 57%;
					max-height: 340px;
					
					.collapse_cont{
						width: 100%;
						height: 115px;
						overflow: hidden;
					}
				}
				
			}
			
		}
		
	}
</style>
