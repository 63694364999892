<template>
	<div id="Titletext">
		<!-- 样式一 -->
		<div class="titletext titlelista" v-if="titletextList.titletextype == 1" :style="titletextList.positiontype == 1 ? start: titletextList.positiontype == 2?center:end">
			<div class="titlea">
				<p class="titlea_title">
					<span :style="fontSize">{{titletextList.title}}</span>
					<span :style="ticolor">/</span>
					<span :style="vticolor">{{titletextList.vicetitle}}</span>
				</p>
				<p class="titlea_line" :style="linecolor"></p>
				<p class="titlea_cont" :style="smallcolor">{{titletextList.smalltitle}}</p>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(titletextList.id)"></i>
		</div>
		
		<!-- 样式二 -->
		<div class="titletext titlelistb" v-if="titletextList.titletextype == 2" :style="titletextList.positiontype == 1 ? start: titletextList.positiontype == 2?center:end">
			<div class="titleb">
				<p class="titleb_title">
					<span :style="fontSize">{{titletextList.title}}</span>
					<span :style="vticolor">{{titletextList.vicetitle}}</span>
				</p>
				<p class="titleb_line" :style="linecolor"></p>
				<p class="titleb_cont" :style="smallcolor">{{titletextList.smalltitle}}</p>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(titletextList.id)"></i>
		</div>
		
		<!-- 样式三 -->
		<div class="titletext titlelistc" v-if="titletextList.titletextype == 3" :style="titletextList.positiontype == 1 ? start: titletextList.positiontype == 2?center:end">
			<div class="titlec">
				<p class="titlec_title">
					<img :src="titletextList.lefturl" alt="">
					<span :style="fontSize">{{titletextList.title}}</span>
					<img :src="titletextList.righturl" alt="">
				</p>
				<p class="titlec_cont" :style="vticolor">{{titletextList.vicetitle}}</p>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(titletextList.id)"></i>
		</div>
		
		<!-- 样式四 -->
		<div class="titletext titlelistd" v-if="titletextList.titletextype == 4" :style="titletextList.positiontype == 1 ? start: titletextList.positiontype == 2?center:end">
			<div class="titled">
				<p :style="vticolor">{{titletextList.vicetitle}}</p>
				<p :style="fontSize">{{titletextList.title}}</p>
				<p :style="linecolor"></p>
				<p :style="smallcolor">{{titletextList.smalltitle}}</p>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(titletextList.id)"></i>
		</div>
		
	</div>
</template>

<script>
	export default{
		data() {
			return {
				
			}
		},
		props:{
			titletextList:{
				type: Object,
				value:{}
			}
		},
		computed:{
			start(){
				return `justify-content: start`
			},
			center(){
				return `justify-content: center`
			},
			end(){
				return `justify-content: flex-end`
			},
			ticolor(){
				return `color: ${this.titletextList.titleColor}`
			},
			vticolor(){
				return `color: ${this.titletextList.vicetitleColor}`
			},
			smallcolor(){
				return `color: ${this.titletextList.smalltitleColor}`
			},
			linecolor(){
				return `border-color: ${this.titletextList.lineColor}`
			},
			fontSize(){
				return `color: ${this.titletextList.titleColor};font-size: ${this.titletextList.fontSize}px`
			}
		},
		methods: {
			showDeleteWrapper(id){
				this.$emit('changeDelet',id)
			}
		},
	}
</script>

<style lang="scss">
	#Titletext{
		background: #fff;
		padding-bottom: 10px;
		
		.titletext{
			width: 1200px;
			margin: auto;
			padding: 20px 0;
			position: relative;
			display: flex;
		}
		
		.titletext:hover{
			border: 1px dashed #409EFF;
		}
		
		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: 0px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}
		
		// 样式一
		.titlelista{
			.titlea{
				width: 450px;
				
				&_title{
					text-align: center;
					padding-bottom: 8px;
					position: relative;
					
					>span:nth-child(1){
						font-size: 16px;
						font-weight: bold;
						color: #309FEF;
						margin-right: 8px;
					}
					
					>span:nth-child(2){
						font-size: 18px;
						color: #309FEF;
						margin-right: 8px;
					}
					
					>span:nth-child(3){
						font-size: 16px;
						color: #96CFF6;
						font-style: italic;
					}
				}
				
				&_line{
					border: 0.7px solid #D5EBFB;
				}
				
				&_cont{
					margin-top: 8px;
					font-size: 12px;
					color: #999;
				}
			}
		}
		
		// 样式二
		.titlelistb{
			.titleb{
				width: 300px;
				display: flex;
				
				&_title{
					width: 180px;
					margin-right: 8px;
					line-height: 20px;
					
					>span{
						display: block;
					}
					
					>span:first-child{
						font-size: 16px;
					}
				}
				
				&_line{
					border-right: 1px solid red;
					margin-right: 10px;
				}
				
				&_cont{
					text-align: left;
					margin-left: 3px;
				}
			}
		}
		
		// 样式三
		.titlelistc{
			.titlec{
				width: 300px;
				text-align: center;
				
				&_title{
					margin-right: 8px;
					line-height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 12px;
					
					>span{
						font-size: 16px;
						margin: 0 15px;
					}
					
					>img{
						width: 30px;
						height: 30px;
					}
				}
			}
		}
		
		// 样式四
		.titlelistd{
			.titled{
				width: 300px;
				text-align: center;
				
				>p:nth-child(1){
					font-size: 12px;
				}
				
				>p:nth-child(2){
					font-size: 16px;
					padding: 4px 0;
				}
				
				>p:nth-child(3){
					border: 0.7px solid red;
					margin-bottom: 4px;
				}
				>p:nth-child(4){
					width: 200px;
					text-align: center;
					padding: 0 40px;
				}
			}
		}
	}
</style>
