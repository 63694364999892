import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'
import store from "@/store"
class CustomPage{
    /**
     * 获取自定义页面详情
     * @param {number} id 
     * @param {function} fn 
     */
    getCustomPageDetail(id,fn){
        $post("getPageDetail",{id}).then(res=>{
            fn(res.data)
        })
    }
    /**
     * 新增或编辑页面
     * @param {object} param 
     * @param {number} id 
     * @param {function} fn 
     */
    addOrEditCustomPage(param,id,fn){
        tool.confirm("确认要保存当前页面吗？").then(()=>{
            if( id ) param.id = id
            $post("createOrUpdatePage",param).then(res=>{
                tool.message("保存成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    addElement(list,timeId,type){
        // jump:1,	//1普通路径跳转 2小程序打开新的小程序 3=拨打电话 4=导航
        if( type == 'slide' ){
            list.push({
                id: timeId, type: 'slide', slide_type:1, focus: true, focusColor: '#ffffff', swiperHeight: 180,
                list: [
                    { url:  tool.getStaticSrc("admin/custom-phone.jpg"), path: '', jump:1, appid:'', mini_path:''},
                    { url:  tool.getStaticSrc("admin/custom-phone.jpg"), path: '', jump:1, appid:'', mini_path:'', }
                ]
            })
        }
    
        if( type == 'navigation'){
            let arr = []
            for (let i = 0; i < 4; i++) {
                arr.push(
                    { title:'按钮文字'+(i+1), url:tool.getStaticSrc("admin/custom-phone.jpg"), path: '', jump:1, appid:'', mini_path:'' }
                )
            }
            list.push({
                id: timeId, type: 'navigation', column: 4, fontColor: "#333333", bgColor:"#fff", fontSize:12, radius: 50,
                list:arr
            })
        }
    
        if( type == 'advert' ){
            list.push({
                id: timeId, type: 'advert', bgColor:'#fff', selectType: 1, height: 150,
                list: [{
                    url:  tool.getStaticSrc("admin/custom-phone.jpg"),
                    width: 50, path: '', appid:'', jump:1, mini_path:'',
                }]
            })
        }
        if( type == 'kd-popup-advert' ){
            list.push({
                id: timeId, 
                type: 'kd-popup-advert',
                cover:"",
                path: '', appid:'', jump:1, mini_path:''
            })
        }
        if( type == 'headline'){
            list.push({
                id: timeId, type: 'headline', text: '这是标题', fontColor: '#333333', 
                fontSize: 15, bgColor: '#999999', padTb: 10, padLr: 10, 
                align: 'left', path:'', icon: '', appid:'', mini_path:'', jump:1
            })
        }
        if( type == 'blank'){
            list.push({ id: timeId, type: 'blank', bgColor: '#eeeeee', height: 20 })
        }
    
        if( type == 'lines'){
            list.push({ id: timeId, type: 'lines', styles: 'solid', lineColor: '#000000', bgColor: '#ffffff', lineHeight: 1, height: 10 })
        }
    
        if( type == 'glossarys'){
            list.push({
                id: timeId, type: 'glossarys', styles: 1, titleSize: 18, titleColor: '#000000', bgColor: '#ffffff',
                list: [{
                    url:  tool.getStaticSrc("admin/custom-phone.jpg"),
                    title: '这是图文集标题', innerText: '这是图文集副标题', path: '', appid:'', jump:1, mini_path:'',
                }]
            })
        }
    
        if( type == 'photo' ){
            list.push({
                id: timeId, type: 'photo', styles: 1, column: 1, radius: 0,
                list: [{
                    url:  tool.getStaticSrc("admin/custom-phone.jpg"),
                    title: '这是图片标题', path: '', appid:'', jump:1, mini_path:'',
                }]
            })
        }
    
        if( type == 'goods' ){
            list.push({
                id: timeId,
                type: 'goods',
                fontColor: '#333333',
                bgColor: '#f4f4f4',
                priceColor: '#333333',
                btnColor: '#333333',
                listType:1,
                limit:3,
                isShopname: true,
                isPrice: true,
                isPaybtn: true,
                btnstyle: 1,
                isMarker: true,
                markerstyle: 1,
                markertext:"",
                list:[],
                goodstype:1,
                goods_sort:'7',	//默认排序方式
            })
        }
    
        if( type == 'coupon' ){
            list.push({
                id: timeId, type: 'coupon', title:'领券专区', is_more:true, couponarray: 1, couponcard: 1, couponcolor: 1, lineColor: '#e1d1d1',
                list:[ { id:0, low_cash_price: 0, coupon_price: 0, coupon_name: '优惠券名称', bg1:'#c38dff', bg2:'#4f53ff', type:1, } ]
            })
        }
    
        if( type == 'live' ){
            list.push({ id: timeId, livearray: 1, type: 'live', title:'当前直播', livemore:true, moreColor: '#333', })
        }
    
        if( type == 'weather' ){
            list.push({ id: timeId, type: 'weather', url:tool.getStaticSrc("admin/weather-img.png"), })
        }
        if( type == 'kd-official' ){
            list.push({ id: timeId, type: 'kd-official', url:tool.getStaticSrc("admin/offical.png"), })
        }
        if( type == 'kd-device' ){
            list.push({ id: timeId, type: 'kd-device', did:"", titleFont:"15px", card:true, url:tool.getStaticSrc("admin/kd-device.png") })
        }
    
        if( type == 'information'){
            list.push({
                id: timeId,
                type: 'information',
                icon_url:tool.getStaticSrc("admin/zixun.png"),
                title_one:'动态',
                title_one_color:'#00c657',
                title_two:'新闻',
                title_two_color:'#6170ff',
                list:[]
            })
        }
    
        if( type =='kd-home-top' ){
            list.push({
                id: timeId, type: 'kd-home-top', bg:'#f4ad2f',
                list:[
                    {
                        url: tool.getStaticSrc("admin/custom-phone.jpg"),
                        path: '', appid:'', jump:1, mini_path:'',
                    }
                ],
            })
        }
        if( type =='kd-store' ){
            list.push({
                id: timeId,
                type: 'kd-store',
                title:'优选店铺',
                is_more:true,
                active_color:'#f4ad2f',
                limit:3,
                is_care:1,
                store_style:3,
            })
        }
        if( type =='kd-goods-card' ){
            list.push({
                id: timeId,
                type: 'kd-goods-card',
                title:'优选店铺',
                bg: tool.getStaticSrc("admin/custom-phone.jpg"),
                list:[
                    {
                        id:0,
                        cover: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
                        goods_name: '这里是商品标题(商品从设定商品中读取)',
                        price: '20.00',
                    },
                    {
                        id:0,
                        cover: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
                        goods_name: '这里是商品标题(商品从设定商品中读取)',
                        price: '20.00',
                    },
                    {
                        id:0,
                        cover: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
                        goods_name: '这里是商品标题(商品从设定商品中读取)',
                        price: '20.00',
                    }
                ]
            })
        }
        if( type =='kd-assist-one' ){
            list.push({
                id: timeId,
                type: 'kd-assist-one',
                title_one:'土地租赁',
                title_two:'实时监控',
                list:[
                    {name:'A区',bg:'#007AFF',path:''},
                    {name:'B区',bg:'#4CD964',path:''},
                    {name:'C区',bg:'#4CD964',path:''},
                ]
            })
        }
    
        if( type == 'kd-video'){
            list.push({ id: timeId, type: 'kd-video', url:'', cover:'' })
        }
        
        if( type == 'kd-hotarea' ){
            list.push({ id:timeId, type:'kd-hotarea', cover:tool.getStaticSrc("admin/custom-phone.jpg"), height:250, list:[] })
        }
        
        // 官网
        if( type == 'topbar' ){
            list.push({
                id: timeId,
                type: 'topbar',
                pageName: '',
                navtype: 1,
                logo:  tool.getStaticSrc("admin/custom-phone.jpg"),
                list: [{ title: '导航标题', path: '' } ]
            })
        }
        
        if( type == 'swiper' ){
            list.push({
                id: timeId,
                type: 'swiper',
                slide_type: 1,
                focus: true,
                focusColor: '#ffffff',
                swiperHeight: 400,
                titletext: '智慧农业探索互联网农业',
                vicetitle: '线上租地种植认养、农业新电商、实时监控直播，互联网+互联网农业，改变传统农业经营新模式',
                videourl:'https://0.ss.faisys.com/image/rimage/section/banner/default/flv.mp4',
                list: [{
                        url: 'https://kundian.cqkundian.com/farm/1/jpg/20200921/6d6f27df4c86af2657d4aa698fd3723d1600654855.jpg',
                        path: ''
                    }
                ]
            })
        }
        
        // 官网功能导航
        if( type == 'funav' ){
            list.push({
                id: timeId,
                type: 'funav',
                function_type: 1,
                SelectColor: '#FF510D',
                SelectextColor: '#fff',
                oneColor: '#67C23A',
                twoColor: '#FF0055',
                threeColor: '#A8C001',
                fourColor: '#FF4400',
                fiveColor: '#FFC400',
                sixColor: '#202020'
            })
        }
        
        // 优先推荐
        if( type == 'recomshop' ){
            list.push({
                id: timeId,
                type: 'recomshop',
                recomshop_type: 1,
                rows: '10',
                titleColor: '#333',
                priceColor: '#FF5500',
                salesvolume: true,
                url: tool.getStaticSrc("admin/custom-phone.jpg"),
                path: ''
            })
        }
        
        // 优选店铺
        if( type == 'optshop' ){
            list.push({ id: timeId, type: 'optshop', optshop_type: 1, rows: '6' })
        }
        
        // 租地种植
        if( type == 'land' ){
            list.push({
                id: timeId,
                type: 'land',
                landtype: 1,
                titleColor: '#333',
                briefColor: '#333',
                priceColor: 'ff5500',
                url:  tool.getStaticSrc("admin/custom-phone.jpg"),
                path: ''
            })
        }
        
        // 畜牧认养
        if( type == 'adopt' ){
            list.push({
                id: timeId,
                type: 'adopt',
                adoptype: 1,
                titleColor: '#333',
                priceColor: 'ff5500',
                url:  tool.getStaticSrc("admin/custom-phone.jpg"),
                path: ''
            })
        }
        
        // 热销产品
        if( type == 'hot' ){
            list.push({
                id: timeId,
                type: 'hot',
                hotype: 1,
                titleColor: '#333',
                priceColor: '#FF5500',
                salesvolume: true,
                url: tool.getStaticSrc("admin/custom-phone.jpg"),
                path: ''
            })
        }
        
        // 新品上架
        if( type == 'product' ){
            list.push({
                id: timeId,
                type: 'product',
                productype: 1,
                titleColor: '#333',
                priceColor: '#FF5500',
                salesvolume: true,
                url: tool.getStaticSrc("admin/custom-phone.jpg"),
                path: ''
            })
        }
        
        // 新闻资讯
        if( type == 'news' ){
            list.push({
                id: timeId,
                type: 'news',
                url: tool.getStaticSrc("admin/custom-phone.jpg"),
                path: '',
                rightimg: tool.getStaticSrc("admin/custom-phone.jpg"),
                rightpath: '',
                list: [{
                    id:0,
                    cover: tool.getStaticSrc("admin/custom-phone.jpg"),
                    title: '这里是资讯信息标题',
                    time: ''
                }]
            })
        }
        
        // DIY图片
        if( type == 'diyimg' ){
            list.push({
                id: timeId,
                type: 'diyimg',
                diyimgtype: 1,
                list: [{
                        url: tool.getStaticSrc("admin/custom-phone.jpg"),
                        path: ''
                    }
                ]
            })
        }
        
        // 图文列表
        if( type == 'textlist' ){
            list.push({
                id: timeId,
                type: 'textlist',
                textype: 1,
                list: [{
                        url: tool.getStaticSrc("admin/custom-phone.jpg"),
                        title: '我是标题',
                        cont: '我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容',
                        path: '',
                        more: false
                    }
                ]
            })
        }
        
        // 文字标题
        if( type == 'titletext' ){
            list.push({
                id: timeId,
                type: 'titletext',
                titletextype: 1,
                positiontype: 1,
                title: '这是标题',
                vicetitle: 'OUR SERVICE',
                titleColor: '#309FEF',
                vicetitleColor: '#309FEF',
                smalltitle: '这个是内容这个是内容这个是内容这个是内容这个是内容',
                smalltitleColor: '#999',
                lineColor: '#D5EBFB',
                fontSize: 16,
                lefturl: tool.getStaticSrc("admin/custom-phone.jpg"),
                righturl: tool.getStaticSrc("admin/custom-phone.jpg")
                
            })
        }
        
        // 监控
        if( type == 'monitor' ){
            list.push({
                id: timeId,
                type: 'monitor',
                monitortype: 1,
                list: [{ url: '', path: '' } ]
            })
        }
        
        // 侧边导航
        if( type == 'sidenav' ){
            list.push({ id: timeId, type: 'sidenav', sidenavtype: true })
        }
    
        return list;
    }

    changeAdvert(count){
        let arr = []
        for (var i = 0; i < count; i++) {
            arr.push({
                url: tool.getStaticSrc("admin/custom-phone.jpg"),
                width: 50,
                path: '',
                appid:'',
                jump:1,
            })
        }
        return arr
    }

    changePhotoColumn(count){
        let arr = []
        for (var i = 0; i < count; i++) {
            arr.push({
                url:  tool.getStaticSrc("admin/custom-phone.jpg"),
                title: '这是图片标题',
                path: '',
                appid:'',
                jump:1,
                mini_path:'',
            })
        }
        return arr
    }


    /**
     * 获取商家管理中心页面初始化数据
     */
    getStoreCenterPage = function(){
        let _p = '/sub_package/marketing/pages/store/'
        let page = {
            cert:{title:'资质认证',path:_p+'cert_center',show:true},
            gird:[
                {title:'农场订单',show:true,list:[
                    {name:'商品订单',icon:tool.getStaticSrc('store/icon16.png'),path:_p+'platform/shop_order',show:true},
                    {name:'认养订单',icon:tool.getStaticSrc('store/icon1.png'),path:_p+'platform/adopt_order',show:true},
                    {name:'种植订单',icon:tool.getStaticSrc('store/icon2.png'),path:_p+'platform/land_order',show:true},
                    {name:'批发订单',icon:tool.getStaticSrc('store/icon3.png'),path:_p+'wholesale/order',show:true},
                ]},
                {title:'农场管理',show:true,list:[
                    {name:'商品管理',icon:tool.getStaticSrc('store/icon7.png'),path:_p+'platform/goods',show:true},
                    {name:'认养管理',icon:tool.getStaticSrc('store/icon4.png'),path:_p+'platform/adopt',show:true},
                    {name:'土地管理',icon:tool.getStaticSrc('store/icon6.png'),path:_p+'platform/land',show:true},
                    {name:'种子管理',icon:tool.getStaticSrc('store/icon6.png'),path:_p+'platform/seed',show:true},
                    {name:'批发管理',icon:tool.getStaticSrc('store/icon5.png'),path:_p+'wholesale/goods_list',show:true},
                    {name:'监控管理',icon:tool.getStaticSrc('store/icon10.png'),path:_p+'platform/live',show:true},
                    {name:'活动管理',icon:tool.getStaticSrc('store/icon.png'),path:_p+'active/list',show:true},
                    {name:'功能开关',icon:tool.getStaticSrc('store/icon.png'),path:_p+'platform/set',show:true},
                ]},
                {title:'农场管理',show:true,list:[
                    {name:'我的钱袋',icon:tool.getStaticSrc('/store/icon12.png'),path:_p+'myinfo/bill',show:true},
                    {name:'保证金',icon:tool.getStaticSrc('/store/icon13.png'),path:_p+'bond',show:true},
                    // {name:'客户通知',icon:tool.getStaticSrc('/store/icon14.png'),path:'/sub_package/contact/pages/list',show:true},
                    {name:'农场资料',icon:tool.getStaticSrc('/store/icon15.png'),path:_p+'myinfo/index',show:true},
                ]},
            ],
            stat:{title:'店铺数据',show:true,list:[
                {name:'今日订单',value:0,show:true},
                {name:'待付款',value:0,show:true},
                {name:'待发货',value:0,show:true},
                {name:'今日成交量',value:0,show:true},
                {name:'今日成交金额',value:0,show:true},
                {name:'今日访客',value:0,show:true},
            ]}
        }
        return page 
    }


    /**
     * 获取PC自定义页面列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} cb 
     */
    getPcPage(page,limit,extra,cb){
        let param = { pre_page:page,limit}
        if( extra && extra.key ){
            param.key = extra.key
        }
        common.getDataList("getWebsitePageList",param).then(res=>cb(res))
    }

    /**
     * 获取pC自定义页面详情
     * @param {number} id 
     * @param {function} cb 
     */
    getPcPageDetail(id,cb){
        $post('getWebSitePage',{ id }).then(res=>cb(res.data))
    }

    /**
     * 保存页面
     * @param {object} param 
     */
    setPcPage(param){
        tool.confirm("确认要保存当前页面吗？").then(()=>{
            $post('setWebSitePage',param).then(res=>{
                tool.message("保存成功")
            })	
        }).catch(()=>{})
    }

    /**
     * pc自定义页面设置默认
     * @param {number}} id 
     * @param {function} cb 
     */
    setPcPageDefault(id,cb){
        tool.confirm("确认将该页面设置为默认页吗？").then(()=>{
            $post('setWebSitePageSign',{sign:'home',id}).then(res=>{
                tool.message("操作成功")
                cb(res.data)
            })	
        }).catch(()=>{})
    }

    /**
     * pc自定义页面复制
     * @param {number} id 
     * @param {function} cb 
     */
    pcPageCopy(id,cb){
        tool.confirm("确认要复制当前选择的页面吗?").then(()=>{
            $post('copyPageDetail',{id}).then(res=>{
                tool.message("操作成功")
                cb(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 删除自定义页面
     * @param {number} id 
     * @param {function} cb 
     */
    deletePcPage(id,cb){
        tool.confirm("确认要删除选择的页面吗?").then(()=>{
            $post('delWebSitePage',{id}).then(res=>{
                tool.message("操作成功")
                cb(res.data)
            })
        }).catch(()=>{})
    }

    getUcTool(){
        let permModule = store.state.modulesPerm
        let data = [
            {name:"领券",icon:"ri-coupon-line",iconColor:'#000',uniid:'coupon',app_show:true,wxapp_show:true},
            {name:"我的优惠券",icon:"ri-coupon-line",iconColor:'#000',uniid:'my_coupon',app_show:true,wxapp_show:true},
            {name:"收货地址",icon:"ri-map-pin-line",iconColor:'#000',uniid:'address',app_show:true,wxapp_show:true},
            {name:"常见问题",icon:"ri-question-line",iconColor:'#000',uniid:'question',app_show:true,wxapp_show:true},
            {name:"购物车",icon:"ri-shopping-cart-line",iconColor:'#000',uniid:'cart',app_show:true,wxapp_show:true},
            {name:"专属客服",icon:"ri-customer-service-2-line",iconColor:'#000',uniid:'exclusive_service',app_show:true,wxapp_show:true},
            {name:"签到",icon:"ri-calendar-check-line",iconColor:'#000',uniid:'sign',app_show:true,wxapp_show:true},
            {name:"收藏夹",icon:"ri-heart-2-line",iconColor:'#000',uniid:'collect',app_show:true,wxapp_show:true},
        ]

        //土地板块
        if( permModule.land == 1 ){ 
            data.push({name:"我的土地",icon:"ri-layout-masonry-line",iconColor:'#000',uniid:'land',app_show:true,wxapp_show:true})
            data.push({name:"服务订单",icon:"ri-file-list-3-line",iconColor:'#000',uniid:'land_server_order',app_show:true,wxapp_show:true})
        }

        //认养板块
        if( permModule.adopt == 1 ){
            data.push(
                {name:"认养订单",icon:"ri-mickey-line",iconColor:'#000',uniid:'adopt',app_show:true,wxapp_show:true},
            )
        }

        //活动订单
        if( permModule.event == 1 ){
            data.push(
                {name:"活动订单",icon:"ri-bookmark-3-line",iconColor:'#000',uniid:'event',app_show:true,wxapp_show:true},
            )
        }

        //分销
        if( permModule.sales_share == 1 ){
            data.push(
                {name:"分销中心",icon:"ri-share-line",iconColor:'#000',uniid:'sales_share',app_show:true,wxapp_show:true},
            )
        }

        //多商户
        if( permModule.multi_merchant == 1 ){
            data.push(
                {name:"农场入驻",icon:"ri-store-3-line",iconColor:'#000',uniid:'store_apply',app_show:true,wxapp_show:true},
                {name:"全部店铺",icon:"ri-store-3-line",iconColor:'#000',uniid:'store_all',app_show:true,wxapp_show:true}
            )
        }

        //土地交换订单
        if( permModule.land_exchange == 1 ){
            data.push(
                {name:"我要换",icon:"ri-exchange-line",iconColor:'#000',uniid:'land_exchange',app_show:true,wxapp_show:true},
            )
        }
        //土地2d
        if( permModule.land_2d == 1 ){
            data.push(
                {name:"土地工单",icon:"ri-file-list-3-line",iconColor:'#000',uniid:'land_work_order',app_show:true,wxapp_show:true},
            )
        }
        return data
    }
}

const customPageModel = new CustomPage()
export default customPageModel