<template>
	<div id="landList">
		<div class="boxs landlista" v-if="landList.landtype == 1">
		    <div class="boxs-top">
		        <p class="top-title">租地种植</p>
		    </div>
		    <div class="boxs-content">
		        <div class="box-right">
		            <ul class="box-list">
		                <!-- 租地 -->
		                <li class="box-item" v-for="(item, index) in leasedland" :key="index">
		                    <a><img :src="item.cover" alt="" /></a>
		                    <div class="box_explain">
		                        <p class="explain_title">
		                            <a class="box-title">{{ item.land_name }}</a>
		                        </p>
		                        <p class="box-price">￥{{ item.min_cost }}元起</p>
		                    </div>
		                    <div class="boxs_address">
		                        <p class="el-icon-date"></p>
		                        <p :title="item.land_intro">{{item.land_intro}}</p>
		                    </div>
		                </li>
		            </ul>
		        </div>
		    </div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(landid)"></i>
		</div>
		
		<!-- 样式二 -->
		<div class="boxs landlistb" v-if="landList.landtype == 2">
		    <div class="boxs-top">
		        <p class="top-title">租地种植</p>
		    </div>
		    <div class="boxs-content">
		        <div class="box-right">
		            <ul class="box-list">
		                <!-- 租地 -->
		                <li class="box-item" v-for="(item, index) in leasedland" :key="index">
		                    <a><img :src="item.cover" alt="" /></a>
		                    <div class="box_explain">
		                        <p class="explain_title">
		                            <a class="box-title">{{ item.land_name }}</a>
		                        </p>
		                        <p class="box-price">￥{{ item.min_cost }}元起</p>
		                    </div>
		                    <div class="boxs_address">
		                        <p class="el-icon-date"></p>
		                        <p :title="item.land_intro">{{item.land_intro}}</p>
		                    </div>
		                </li>
		            </ul>
		        </div>
		    </div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(landid)"></i>
		</div>
		
		<!-- 样式三 -->
		<div class="boxs landlistc" v-if="landList.landtype == 3">
			<div class="land_title_index"><p>租地种植</p></div>
			<div class="land_mall">
			    <ul class="landcont">
			        <li v-for="(item, index) in leasedland" :key="index">
			            <img class="land_img" :src="item.cover" />
			            <p class="land_title" :title="item.land_name">{{ item.land_name }}</p>
			            <div class="land_polic">
			                <p class="carprice">¥{{ item.min_cost }}元起</p>
			            </div>
			        </li>
					<img class="carousimg" :src="landList.url" alt="">
					<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(landid)"></i>
			    </ul>
			</div>
		</div>
		
		<!-- 样式四 -->
		<div class="boxs landlistd" v-if="landList.landtype == 4">
			<div class="landlistd_title">
				<img src="../../assets/img/png/a.png" alt="">
				<p>租地种植 PRODUCTS</p>
			</div>
			<img class="landlistd_img" :src="landList.url" alt="">
			<div class="boxs-content">
			    <div class="box-right">
			        <ul class="box-list">
			            <!-- 租地 -->
			            <li class="box-item" v-for="(item, index) in leasedland" :key="index">
			                <a><img :src="item.cover" alt="" /></a>
			                <div class="box_explain">
			                    <p class="explain_title">
			                        <a class="box-title">{{ item.land_name }}</a>
			                    </p>
			                    <p class="box-price">￥{{ item.min_cost }}元起</p>
			                </div>
			                <div class="boxs_address">
			                    <p class="el-icon-date"></p>
			                    <p :title="item.land_intro">{{item.land_intro}}</p>
			                </div>
			            </li>
			        </ul>
			    </div>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(landid)"></i>
		</div>
		
		
	</div>
</template>

<script>
import { $post } from '@/api/http'
export default{
	data() {
		return {
			category_id: 0,
			leasedland: [],
			adopt_list: [],
		}
	},
	props: {
		landid: {
			trpe: Number
		},
		landList: {
			type: Object,
			value:{}
		}
	},
	created() {
		this.getLandListByCategory(20);
	},
	watch:{
		'landList.landtype':function(val){
			if(val == 3){
				this.getLandListByCategory(8);
			}else{
				this.getLandListByCategory(20);
			}
		}
	},
	methods: {
		// 租地
		getLandListByCategory(limit) {
			$post('getLandListByCategory',{limit},true,3).then(res => {
				this.leasedland = res.data;
			}).catch(err => {
				console.log(err);
			});
		},
		showDeleteWrapper(id){
			this.$emit('changeDelet',id)
		}
	},
	
}
</script>

<style lang="scss">
	#landList{
		width: 100%;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
		clear: both;
		
		
		.boxs {
		    position: relative;
		    width: 1200px;
		    height: auto;
		    margin: 0 auto;
			background: #fff;
		}
		
		.boxs:hover{
			border: 1px dashed #409EFF;
		}
		
		// 样式一
		.landlista{
			.boxs-top {
			    width: 100%;
			    display: flex;
			    justify-content: space-between;
			    align-items: center;
				padding: 20px 0 20px 25px;
				border-bottom: 1px solid #eee;
			}
			
			.top-title {
			    font-size: 18px;
			    border-left: 3px solid #67c23a;
			    padding-left: 10px;
			}
			
			.top-sub {
			    float: right;
			    display: block;
			    font-size: 15px;
			    color: #424242;
			    cursor: pointer;
			}
			
			.boxs-content {
			    display: flex;
			    justify-content: space-between;
			}
			
			.sub-icon {
			    color: #b0b0b0;
			    font-size: 20px;
			}
			
			.top-sub:hover {
			    color: #67c23a;
			}
			
			.top-sub:hover .sub-icon {
			    color: #67c23a;
			}
			
			.box-right {
			    // width: 992px;
			    // height: 500px;
			}
			
			.box-item {
			    position: relative;
			    padding: 25px;
			    width: 300px;
			    height: 320px;
			    background: #fff;
			    transition: all 0.3s;
			    box-sizing: border-box;
			    cursor: pointer;
				border-right: 1px solid #eee;
				border-bottom: 1px solid #eee;
			}
			
			.adop_cont{
			    height: 250px;
			}
			
			.box-item:nth-child(4n){
			    border-right: none;
			}
			
			// .box-item:hover {
			//     transform: translateY(-1px);
			//     box-shadow: 5px 5px 20px #ccc;
			// }
			
			.discount-label {
			    position: absolute;
			    top: 0;
			    left: 50%;
			    width: 64px;
			    height: 20px;
			    line-height: 20px;
			    margin-left: -32px;
			    font-size: 12px;
			    text-align: center;
			    color: #fff;
			    z-index: 4;
			}
			
			.discount-label .free {
			    background-color: #ffac13;
			}
			
			.discount-label .new {
			    background-color: #83c44e;
			}
			
			.discount-label .discount {
			    background-color: #e53935;
			}
			
			.old-price {
			    color: #b0b0b0;
			    text-decoration: line-through;
			}
			
			.box-right img {
			    display: block;
			    width: 100%;
			    height: 205px;
			    margin: 0 auto;
			}
			
			.box_explain {
			    display: flex;
			    padding: 10px;
			    align-items: center;
			    justify-content: space-between;
			}
			
			.box-title {
			    font-size: 14px;
			    text-align: center;
			    color: #333;
			    font-weight: normal;
			}
			
			.box-desc {
			    margin: 0 10px 10px;
			    height: 18px;
			    font-size: 12px;
			    text-align: center;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    overflow: hidden;
			    color: #b0b0b0;
			}
			
			.box-price {
			    color: #ff6700;
			    font-size: 14px;
			    text-align: center;
			}
			
			.box-list {
			    display: flex;
			    flex-flow: row wrap;
			    justify-content: flex-start;
			    width: 100%;
			    height: 100%;
			}
			
			.explain_title {
			    width: 10rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
				text-align: left;
			}
			
			.boxs_address{
			    padding: 0 10px;
			    display: flex;
			    align-items: center;
				text-align: left;
			    color: #999;
			    font-size: 14px;
			
			    .el-icon-date{
			        font-size: 18px;
			        margin-right: 10px;
			    }
			}
			
			.boxs_address>p:last-child{
			    width: 19rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			}
		}
		
		// 样式二
		.landlistb{
			padding: 20px;
			
			.boxs-top {
			    width: 100%;
			    // height: 58px;
			    display: flex;
			    justify-content: space-between;
			    align-items: center;
			    margin-bottom: 20px;
			}
			
			.top-title {
			    font-size: 18px;
			    border-left: 3px solid #67c23a;
			    padding-left: 10px;
			}
			
			.top-sub {
			    float: right;
			    display: block;
			    font-size: 15px;
			    color: #424242;
			    cursor: pointer;
			}
			
			.boxs-content {
			    display: flex;
			    justify-content: space-between;
			}
			
			.sub-icon {
			    color: #b0b0b0;
			    font-size: 20px;
			}
			
			.top-sub:hover {
			    color: #67c23a;
			}
			
			.top-sub:hover .sub-icon {
			    color: #67c23a;
			}
			
			.box-right {
			    // width: 992px;
			    // height: 500px;
			}
			
			.box-item {
			    position: relative;
			    margin: 0 32px 14px 0px;
			    padding: 0px 0 40px;
			    width: 275px;
			    height: 290px;
			    background: #fff;
			    transition: all 0.3s;
			    box-sizing: border-box;
			    cursor: pointer;
			}
			
			.adop_cont{
			    height: 250px;
			}
			
			.box-item:nth-child(4n){
			    margin-right: 0;
			}
			
			.box-item:hover {
			    transform: translateY(-1px);
			    box-shadow: 5px 5px 20px #ccc;
			}
			
			.discount-label {
			    position: absolute;
			    top: 0;
			    left: 50%;
			    width: 64px;
			    height: 20px;
			    line-height: 20px;
			    margin-left: -32px;
			    font-size: 12px;
			    text-align: center;
			    color: #fff;
			    z-index: 4;
			}
			
			.discount-label .free {
			    background-color: #ffac13;
			}
			
			.discount-label .new {
			    background-color: #83c44e;
			}
			
			.discount-label .discount {
			    background-color: #e53935;
			}
			
			.old-price {
			    color: #b0b0b0;
			    text-decoration: line-through;
			}
			
			.box-right img {
			    display: block;
			    width: 100%;
			    height: 205px;
			    margin: 0 auto;
			}
			
			.box_explain {
			    display: flex;
			    padding: 10px;
			    align-items: center;
			    justify-content: space-between;
			}
			
			.box-title {
			    font-size: 14px;
			    text-align: center;
			    color: #333;
			    font-weight: normal;
			}
			
			.box-desc {
			    margin: 0 10px 10px;
			    height: 18px;
			    font-size: 12px;
			    text-align: center;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    overflow: hidden;
			    color: #b0b0b0;
			}
			
			.box-price {
			    color: #ff6700;
			    font-size: 14px;
			    text-align: center;
			}
			
			.box-list {
			    display: flex;
			    flex-flow: row wrap;
			    justify-content: flex-start;
			    width: 100%;
			    height: 100%;
			}
			
			.explain_title {
			    width: 14rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
				text-align: left;
			}
			
			.boxs_address{
			    padding: 0 10px;
			    display: flex;
			    align-items: center;
			    color: #999;
			    font-size: 14px;
			
			    .el-icon-date{
			        font-size: 18px;
			        margin-right: 10px;
			    }
			}
			
			.boxs_address>p:last-child{
			    width: 19rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
				text-align: left;
			}
		}
		
		// 样式三
		.landlistc{
			background: #fff;
			position: relative;
			padding: 20px 0;
			height: 625px;
			
			.land_title_index{
				text-align: left;
				font-size: 18px;
				border-left: 3px solid #67c23a;
				padding-left: 10px;
				margin-left: 20px;
			}
			.landcont {
			    display: flex;
			    align-items: center;
			    flex-wrap: wrap;
				padding: 0 20px;
			}
			
			.landcont>li {
			    margin: 20px 0px 0;
			    width: 220px;
			    height: 280px;
			    border: 1px solid #ECECEC;
			    border-radius: 0 0 10px 10px;
			    margin-right: 20px;
			    background: #fff;
			    cursor: pointer;
			}
			
			.landcont>li:nth-child(4n){
			    margin-right: 0;
			}
			
			.carousimg{
				position: absolute;
				right: 20px;
				top: 65px;
				width: 190px;
				height: 582px;
			}
			
			.land_img {
			    width: 100%;
			    height: 195px;
			}
			
			.land_title {
			    width: 17rem;
			    padding: 10px 5px 5px;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    font-size: 13px;
				text-align: left;
			}
			
			.land_polic {
			    display: flex;
			    padding: 0 10px 10px 10px;
			    justify-content: space-between;
			    align-items: center;
			    font-size: 15px;
				margin-top: 6px;
			}
			
			.land_polic .carbuynow {
				padding: 0;
				border: none;
			    font-size: 13px;
			}
		}
		
		// 样式四
		.landlistd{
			background: #fff;
			margin-top: 20px;
			position: relative;
			padding-bottom: 20px;
			
			&_title{
				text-align: center;
				position: relative;
				
				>img{
					width: 180px;
					height: 45px;
					margin: 20px 0;
				}
				
				>p{
					position: absolute;
					top: 39%;
					left: 50%;
					margin-left: -70px;
					font-size: 13px;
					color: #67C23A;
					letter-spacing: 1px;
				}
			}
			
			&_img{
				width: 100%;
				height: 220px;
				margin-bottom: 25px;
			}
			
			.boxs-content {
			    display: flex;
			    justify-content: space-between;
			}
			
			.sub-icon {
			    color: #b0b0b0;
			    font-size: 20px;
			}
			
			.top-sub:hover {
			    color: #67c23a;
			}
			
			.top-sub:hover .sub-icon {
			    color: #67c23a;
			}
			
			.box-right {
			    // width: 992px;
			    // height: 500px;
			}
			
			.box-item {
			    position: relative;
			    margin: 0 32px 14px 0px;
			    padding: 0px 0 40px;
			    width: 275px;
			    height: 290px;
			    background: #fff;
			    transition: all 0.3s;
			    box-sizing: border-box;
			    cursor: pointer;
			}
			
			.adop_cont{
			    height: 250px;
			}
			
			.box-item:nth-child(4n){
			    margin-right: 0;
			}
			
			.box-item:hover {
			    transform: translateY(-1px);
			    box-shadow: 5px 5px 20px #ccc;
			}
			
			.discount-label {
			    position: absolute;
			    top: 0;
			    left: 50%;
			    width: 64px;
			    height: 20px;
			    line-height: 20px;
			    margin-left: -32px;
			    font-size: 12px;
			    text-align: center;
			    color: #fff;
			    z-index: 4;
			}
			
			.discount-label .free {
			    background-color: #ffac13;
			}
			
			.discount-label .new {
			    background-color: #83c44e;
			}
			
			.discount-label .discount {
			    background-color: #e53935;
			}
			
			.old-price {
			    color: #b0b0b0;
			    text-decoration: line-through;
			}
			
			.box-right img {
			    display: block;
			    width: 100%;
			    height: 205px;
			    margin: 0 auto;
			}
			
			.box_explain {
			    display: flex;
			    padding: 10px;
			    align-items: center;
			    justify-content: space-between;
			}
			
			.box-title {
			    font-size: 14px;
			    text-align: center;
			    color: #333;
			    font-weight: normal;
			}
			
			.box-desc {
			    margin: 0 10px 10px;
			    height: 18px;
			    font-size: 12px;
			    text-align: center;
			    text-overflow: ellipsis;
			    white-space: nowrap;
			    overflow: hidden;
			    color: #b0b0b0;
			}
			
			.box-price {
			    color: #ff6700;
			    font-size: 14px;
			    text-align: center;
			}
			
			.box-list {
			    display: flex;
			    flex-flow: row wrap;
			    justify-content: flex-start;
			    width: 100%;
			    height: 100%;
			}
			
			.explain_title {
			    width: 14rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
				text-align: left;
			}
			
			.boxs_address{
			    padding: 0 10px;
			    display: flex;
			    align-items: center;
			    color: #999;
			    font-size: 14px;
			
			    .el-icon-date{
			        font-size: 18px;
			        margin-right: 10px;
			    }
			}
			
			.boxs_address>p:last-child{
			    width: 19rem;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    white-space: nowrap;
				text-align: left;
			}
		}
		
		
		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: -15px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}
	}
</style>
