<template>
	<div id="sidenav">
		<div class="sidebarcom-nav" v-if="sidenavList.sidenavtype == true">
		    <div class="sidebarcom-nav-cnt">
		        <!-- 侧边导航内容 -->
		        <div class="sidebarcom-my">
		            <!-- 个人信息 -->
		            <div class="sidebarcom-my-con" @mouseenter="enter()" ref="journalUpward">
		                <i class="iconfont com ri-user-fill" style="font-size: 25px;"><i class="sidebarcom-my-name">账号</i></i>
		                <p class="sidebarcom-hover">个人中心</p>
		            </div>
		
		            <!-- 购物车 -->
		            <div class="sidebarcom-cart" @click="attribute(1)">
		                <div class="">
		                    <div class="sidebarcom-cart-bar">
		                        <i class="iconfont com icon-gouwuchekong-copy"></i>
		                        <p class="sidebarcom-cart-text">购物车</p>
		                        <i class="i-sidebarcom-nub">{{ sidebar_shopcart }}</i>
		                    </div>
		                </div>
		            </div>
		
		            <!-- 优惠券 -->
		            <div class="sidebarcom-coupon" @click="attribute(2)" ref="couponup">
		                <div class="coupon">
		                    <i class="iconfont com ri-coupon-fill i-sidebarcom-coupon"></i>
		                    <p class="sidebarcom-hover">我的优惠券</p>
		                </div>
		            </div>
		            <!-- 店铺收藏 -->
		            <!-- <div class="sidebarcom-shop" @click="attribute(3)" ref="storecollectionup">
		                <div>
		                    <i class="i-sidebarcom-shop iconfont com ri-heart-2-fill"></i>
		                    <p class="sidebarcom-hover">店铺收藏</p>
		                </div>
		            </div> -->
		            <!-- 商品收藏 -->
		            <div class="sidebarcom-fav" @click="attribute(4)" ref="favcollectionup">
		                <div>
		                    <i class="i-sidebarcom-fav iconfont com ri-store-fill"></i>
		                    <p class="sidebarcom-hover">商品收藏</p>
		                </div>
		            </div>
		        </div>
		    </div>
		    <!-- 回到顶部 -->
		    <div class="sidebarcom-nav-ft">
		        <!-- 回到顶部 -->
		        <div class="sidebarcom-top">
		            <div>
		                <a><i class="i-sidebarcom-top el-icon-upload2 iconfont com"></i></a>
		                <p class="sidebarcom-hover">返回顶部</p>
		            </div>
		        </div>
		    </div>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return {
				UserInfo: [],
				personal: false,
				shoppingCart: false,
				distance: 0,
				sidebar_shopcart: 0,
				individual: false,
				my_shopping: false,
				my_coupon: false,
				my_shop: false,
				my_commodity: false,
				signin: true,
				activeName: 0,
				whether_log: true,
				shopping_cart: [],
				couponlist: [], //优惠券
				collection_shop_list: [],
				commodity_collection_list: []
			}
		},
		props:{
			sidenavList:{
				type: Object,
				value:{}
			}
		},
		methods: {
			enter() {
			    this.personal = true;
			    this.individual = true;
			    this.shoppingCart = false;
			    this.signin = true;
			    this.distance = this.$refs.journalUpward.getBoundingClientRect().top;
			},
			cancel() {
			    this.personal = false;
			},
			close() {
			    this.personal = false;
			    this.shoppingCart = false;
			},
			
			// 点击
			attribute(e) {
			    this.shoppingCart = !this.shoppingCart;
			    this.personal = false;
			    if (e === 1) {
			        this.my_shopping = true;
			        this.my_commodity = false;
			        this.my_shop = false;
			        this.my_coupon = false;
			        return;
			    }
			    if (e === 2) {
			        this.my_commodity = false;
			        this.my_shop = false;
			        this.my_shopping = false;
			        this.my_coupon = true;
			        return;
			    }
			    if (e === 3) {
			        this.my_commodity = false;
			        this.my_shop = true;
			        this.my_shopping = false;
			        this.my_coupon = false;
			        return;
			    }
			    if (e === 4) {
			        this.my_commodity = true;
			        this.my_shop = false;
			        this.my_shopping = false;
			        this.my_coupon = false;
			    }
			},
			// 点击图片回到顶部
			backTop() {
			    const that = this;
			    let timer = setInterval(() => {
			        let ispeed = Math.floor(-that.scrollTop / 5);
			        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
			        if (that.scrollTop === 0) {
			            clearInterval(timer);
			        }
			    }, 20);
			},
			scrollToTop() {
			    const that = this;
			    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			    that.scrollTop = scrollTop;
			},
			// 鼠标移入显示收藏提示
			entershop(index) {
			    this.collection_shop_list[index].collection = true;
			},
			// 鼠标移出关闭收藏提示
			closeshop(index) {
			    this.collection_shop_list[index].collection = false;
			},
			// 取消收藏
			cancelshop(index) {
			    this.collection_shop_list[index].selection = false;
			},
			// 恢复收藏
			collectionshop(index) {
			    this.collection_shop_list[index].selection = true;
			},
		},
		mounted() {
		    window.addEventListener('scroll', this.scrollToTop);
		    document.addEventListener('click', e => {
		        if (!this.$el.contains(e.target)) {
		            this.shoppingCart = false;
		        }
		    });
		},
		destroyed() {
		    window.removeEventListener('scroll', this.scrollToTop);
		}
	}
</script>

<style lang="scss">
	#sidenav{
		position: fixed;
		right: 0;
		top: 0;
		z-index: 992;
		width: 36px;
		height: 100%;
		font-family: 'Microsoft YaHei';
		
		.sidebarcom-nav {
		    position: relative;
		    z-index: 101;
		    height: 100%;
		    background-color: #262626;
		}
		
		.sidebarcom-nav-cnt {
		    margin-top: -250px;
		    height: 400px;
		    position: absolute;
		    top: 50%;
		    right: 0;
		}
		
		/* 个人信息 */
		.sidebarcom-coupon,
		.sidebarcom-shop,
		.sidebarcom-fav,
		.sidebarcom-footprint,
		.sidebarcom-my,
		.sidebarcom-survey,
		.sidebarcom-top {
		    position: relative;
		    width: 36px;
		    margin-bottom: 8px;
		    text-align: center;
		    cursor: pointer;
		}
		
		.sidebarcom-my {
		    line-height: 1.8;
		}
		
		.sidebarcom-my-con {
		    padding: 8px 0 15px;
		}
		
		.sidebarcom-my-con .ri-user-fill {
		    position: relative;
		    display: block;
		    width: 36px;
		    font-size: 25px;
		    font-family: vipSidebarFont;
		    line-height: 18px;
		    color: #fff;
		}
		
		.sidebarcom-my-name {
		    font-size: 12px;
		    color: #fff;
		}
		
		.sidebarcom-hover {
		    position: absolute;
		    right: -120px;
		    top: 0;
		    z-index: -1;
		    width: 100px;
		    height: 34px;
		    line-height: 34px;
		    padding: 0 8px;
		    border-radius: 3px 0 0 3px;
		    background-color: #fff;
		    border: 1px solid #ddd;
		    border-right: none;
		    font-size: 14px;
		    text-align: center;
		    -webkit-transition: 0.3s linear;
		    transition: 0.3s linear;
		}
		
		.sidebarcom-my-con:hover {
		    background: #67c23a;
		}
		
		/* 购物车 */
		.sidebarcom-cart-bar {
		    color: #fff;
		    border-bottom: 1px solid #4c4c4c;
		    padding: 10px 0;
		    border-top: 1px solid #4c4c4c;
		}
		
		.icon-gouwuchekong-copy {
		    margin: -1px auto 0;
		    font-size: 24px;
		}
		
		.sidebarcom-cart-text {
		    margin: 0 auto;
		    width: 14px;
		    line-height: 18px;
		    font-size: 14px;
		    color: #fff;
		}
		
		.i-sidebarcom-nub {
		    margin: 5px auto 0;
		    line-height: 22px;
		    text-align: center;
		    color: #fff;
		    position: relative;
		    bottom: -1px;
		}
		
		.sidebarcom-cart:hover {
		    background: #67c23a;
		}
		
		/* 购物车详细信息 */
		.sidebarcom-pop-hd {
		    overflow: hidden;
		    padding: 10px 0;
		    border-bottom: 1px solid #dedcdc;
		}
		
		.sidebarcom-pop-hd .el-icon-close {
		    margin-top: 10px !important;
		}
		
		.sidebarcom-pop-hd span {
		    color: #262626;
		    font-size: 16px;
		}
		
		.sidebarcom-cart-list {
		    overflow: hidden;
		    padding: 10px 0 4px;
		}
		
		.navigation_tips {
		    padding: 5px 3px;
		    line-height: 18px;
		}
		
		.sidebarcom-cart-tips {
		    /* display: none; */
		    overflow: hidden;
		    line-height: 20px;
		    padding: 10px;
		    border: 1px solid #ddd;
		    border-radius: 4px;
		    box-shadow: 0 0 2px #ddd;
		    background-color: #fffbf3;
		    font-size: 12px;
		    color: #666;
		}
		
		.i-sidebarcom-cart-tips {
		    float: left;
		    margin-right: 10px;
		    background-repeat: no-repeat;
		    color: #f40 !important;
		    font-size: 26px;
		}
		
		.sidebarcom-cart-tips-txt {
		    float: left;
		    width: 80%;
		    text-align: left;
		}
		
		.m-cart-list {
		    position: relative;
		    width: 248px;
		}
		
		.sidebarcom-cart-list-inf {
		    float: left;
		    width: 140px;
		    overflow: hidden;
		}
		
		.sidebarcom-cart-list-inf img {
		    float: left;
		    margin-right: 10px;
		    width: 49px;
		    height: 61px;
		}
		
		.sidebarcom-cart-list-name {
		    margin-bottom: 10px;
		    height: 28px;
		    line-height: 14px;
		    overflow: hidden;
		    color: #333;
		    font-family: '\5B8B\4F53';
		}
		
		.sidebarcom-cart-list-size {
		    color: #9f9e9e;
		}
		
		.sidebarcom-cart-list dd {
		    float: left;
		    width: 50px;
		    line-height: 36px;
		    text-align: right;
		}
		
		.sidebarcom-cart-list dd {
		    float: left;
		    width: 50px;
		    line-height: 36px;
		    text-align: right;
		}
		
		.sidebarcom-cart-list .salebg2 {
		    font-size: 14px;
		    font-weight: 100;
		    color: #67c23a;
		}
		
		.J_cart_goods_link {
		    color: #333;
		}
		
		.m-cart-oper {
		    margin-top: 16px;
		    padding: 16px 0 55px;
		    border-top: 1px solid #d3d1d1;
		    background-color: #fff;
		    position: relative;
		    z-index: 9;
		}
		
		.sidebarcom-cart-oper-inf {
		    line-height: 18px;
		    color: #666;
		    text-align: left;
		    padding: 0 0 16px;
		}
		
		.sidebarcom-cart-oper-inf .sidebarcom-cart-count {
		    color: #67c23a;
		    font-weight: 700;
		}
		
		.sidebarcom-cart-oper-inf .salebg2 {
		    font-family: arial;
		    position: absolute;
		    right: 0;
		    font-size: 18px;
		    margin-left: 20px;
		}
		
		.i-sidebarcom-btn {
		    display: block;
		    margin: 0 auto;
		    background-color: #67c23a;
		    border-radius: 3px;
		    color: #fff;
		    text-align: center;
		    cursor: pointer;
		}
		
		.m-cart-oper .u-sidebarcom-btn {
		    margin-top: 0;
		    width: 100%;
		    height: 36px;
		    line-height: 34px;
		    font-size: 16px;
		}
		
		.my-scroll-bar {
		    height: 550px;
		    width: 245px;
		}
		
		.side_navigtion .gm-scrollbar-container {
		    width: 260px;
		}
		
		/* 优惠券 */
		.i-sidebarcom-coupon,
		.i-sidebarcom-shop,
		.i-sidebarcom-fav,
		.i-sidebarcom-survey,
		.i-sidebarcom-top {
		    position: relative;
		    display: block;
		    margin-top: 0;
		    width: 36px;
		    height: 36px;
		    line-height: 36px;
		    background-color: #262626;
			font-size: 20px;
		}
		
		.i-sidebarcom-coupon:hover,
		.i-sidebarcom-shop:hover,
		.i-sidebarcom-fav:hover,
		.i-sidebarcom-survey:hover,
		.i-sidebarcom-top:hover {
		    background: #67c23a;
		}
		
		.ri-coupon-fill {
		    font-family: vipSidebarFont;
		    font-size: 20px;
		    color: #fff;
		}
		
		.sidebarcom-coupon:hover .sidebarcom-hover {
		    right: 36px;
		    transition: 0.2s linear;
		}
		
		/* 已领取的优惠券 */
		.side_navigtion .el-tabs__nav {
		    width: 100%;
		    text-align: center;
		}
		
		.el-tabs__active-bar {
		    left: 33px;
		    background-color: #67c23a;
		}
		
		.el-tabs__item.is-active {
		    color: #67c23a;
		}
		
		.el-tabs__item:hover {
		    color: #67c23a;
		}
		
		.coupon-item {
		    margin: 20px 0 0 2px;
		    width: 240px;
		    min-height: 106px;
		    background-color: #fff;
		    border: 1px solid #ddd;
		    border-radius: 10px 0 0 10px;
		    position: relative;
		}
		
		.coupon-item .item-wrap {
		    min-height: 113px;
		    padding: 0 10px 5px 15px;
		    position: relative;
		}
		
		.coupon-item .coupon-price {
		    float: left;
		    width: 180px;
		    font-family: Arial;
		    font-size: 0;
		    font-weight: 700;
		    display: inline;
		    padding-top: 12px;
		    color: #67c23a;
		}
		
		.coupon-item .coupon-price .token {
		    position: relative;
		    font-size: 16px;
		    font-family: 'microsoft yahei';
		    top: -8px;
		    _top: -8px;
		    left: 2px;
		    font-weight: 400;
		}
		
		.coupon-item .coupon-price span.coupon-val {
		    font-size: 28px;
		}
		
		.coupon-item .coupon-name {
		    font-size: 12px;
		    font-family: 'Microsoft Yahei';
		    font-weight: normal;
		    padding-left: 8px;
		}
		
		.coupon-item .coupon-info {
		    float: left;
		    display: inline;
		    width: 180px;
		    margin: 0 2px 0 0;
		    height: auto;
		    line-height: 18px;
		    color: #666;
		    font-family: 'Microsoft Yahei';
		    position: relative;
		}
		
		.coupon-info .condition {
		    display: inline-block;
		    zoom: 1;
		    height: 18px;
		    color: #777;
		    margin: 0 auto;
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    width: 180px;
		    vertical-align: middle;
		}
		
		.coupon-item .coupon-info p {
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    color: #999;
		}
		
		.coupon-item .btn-get {
		    display: block;
		    text-decoration: none;
		    cursor: pointer;
		    margin: 0 auto;
		    width: 12px;
		    line-height: 19px;
		    font-family: 'microsoft yahei';
		    color: #fff;
		    clear: both;
		    position: absolute;
		    right: 0;
		    top: 0;
		    bottom: 0;
		    background: url(../../assets/img/coupon-new.png) no-repeat;
		    padding: 16px 10px 0;
		}
		
		.dong .btn-get {
		    background-color: #fa9899;
		    background-position: -236px 0px;
		}
		
		.dong .btn-Invalid {
		    background-color: #c3c3c3;
		    background-position: -236px -247px;
		}
		
		.coupon-item .coupon-time {
		    clear: both;
		    color: #999;
		    font-family: 'Microsoft Yahei';
		    width: 190px;
		}
		
		.side_navigtion .el-tab-pane > div:last-child {
		    margin-bottom: 70px;
		}
		
		/* 店铺收藏 */
		.side_navigtion .iconfont {
		    font-family: vipSidebarFont;
		    font-size: 22px;
		}
		
		.com {
		    color: #fff;
		}
		
		.sidebarcom-shop:hover .sidebarcom-hover {
		    right: 36px;
		    transition: 0.2s linear;
		}
		
		.c-brand-onsale {
		    padding-top: 18px;
		}
		
		.m-brand-item {
		    margin: 0 auto 20px;
		    width: 230px;
		    border: 1px solid #cac8c8;
		    box-shadow: 0 0 2px #ddd;
		}
		
		.brand-item-pic {
		    position: relative;
		    width: 230px;
		    height: 133px;
		    background-color: #000;
		}
		
		.m-sidebarcom-love {
		    position: absolute;
		    right: 8px;
		    top: 8px;
		    z-index: 1;
		}
		
		.i-sidebarcom-love {
		    position: absolute;
		    right: 0;
		    top: 0;
		    font-size: 20px;
		    cursor: pointer;
		    color: #f40;
		    z-index: 99;
		}
		
		/* .i-sidebarcom-love:hover .sidebarcom-love-pop{
		    display: block !important;
		  } */
		
		.sidebarcom-love-pop {
		    position: relative;
		    top: 22px;
		    padding: 2px 4px;
		    border: 1px solid #e0e0e0;
		    border-radius: 3px;
		    background-color: #fff;
		}
		
		.brand-item-inf {
		    height: 35px;
		    padding: 0 8px;
		    font-family: '\5B8B\4F53';
		    display: flex;
		    align-items: center;
		    justify-content: space-between;
		}
		
		.brand-item-inf-pire i {
		    font-size: 18px;
		    color: #f40;
		}
		
		.brand-item-inf-name {
		    display: block;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		}
		
		.brand-item-inf a {
		    color: #6d6b6a;
		}
		
		.u-btn-fav-more {
		    display: block;
		    margin: 14px auto 70px;
		    width: 237px;
		    height: 34px;
		    line-height: 34px;
		    border: 1px solid #d8d6d6;
		    border-radius: 3px;
		    background-color: #f9f7f8;
		    text-align: center;
		    font-family: '\5B8B\4F53';
		    color: #000;
		}
		
		/* 商品收藏 */
		.sidebarcom-fav:hover .sidebarcom-hover {
		    right: 36px;
		    transition: 0.2s linear;
		}
		
		.sidebarcom-pop-cnt {
		    width: 244px;
		    padding-bottom: 20px;
		}
		
		.fav-list-cnt {
		    overflow: hidden;
		    margin-right: -10px;
		    margin-top: 20px;
		}
		
		.shopcommodity {
		    font-size: 15px;
		    padding: 10px 0;
		}
		
		.m-fav-brand {
		    float: left;
		    position: relative;
		    margin: 0 10px 12px 2px;
		    width: 113px;
		    height: 176px;
		    border: 1px solid #d9d9d9;
		    box-shadow: 0 0 2px #ddd;
		}
		
		.m-fav-brand img {
		    display: block;
		    width: 113px;
		    height: 142px;
		}
		
		.m-fav-brand .u-sprise-tip {
		    width: 113px;
		    text-align: center;
		    position: absolute;
		    top: 122px;
		    color: #fff;
		    background-color: #e748a6;
		    height: 20px;
		}
		
		.f-hidden {
		    display: none;
		}
		
		.fav-brand-inf {
		    overflow: hidden;
		    height: 34px;
		    line-height: 34px;
		    margin: 0 0px 0 10px;
		    color: #696868;
		    display: flex;
		    align-items: center;
		}
		
		.m-sidebarcom .salebg2 {
		    float: left;
		    font-size: 14px;
		    font-family: arial;
		    color: #f10180;
		}
		
		.fav-brand-inf .size {
		    width: 75px;
		    margin-left: 9px;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		    display: inline-block;
		}
		
		.fav-brand-tips {
		    position: absolute;
		    left: 8px;
		    top: 50px;
		    width: 62px;
		    padding: 6px 6px 6px 26px;
		    border: 1px solid #d9d9d9;
		    border-radius: 3px;
		    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
		    text-align: center;
		    background-color: #fff;
		}
		
		.fav-brand-tips-limit {
		    padding: 6px;
		    width: 88px;
		}
		
		/* 反馈 */
		.sidebarcom-nav-ft {
		    position: absolute;
		    bottom: 20px;
		    right: 0;
		}
		
		.icon-kefu {
		    color: #ccc;
		    height: 36px;
		    font-size: 24px !important;
		}
		
		.sidebarcom-survey:hover .icon-kefu {
		    color: #fff;
		}
		
		.sidebarcom-survey:hover .sidebarcom-hover {
		    right: 36px;
		    transition: 0.2s linear;
		}
		
		/* 回到顶部 */
		.i-sidebarcom-top {
		    color: #ccc;
		    height: 36px;
		}
		
		.sidebarcom-top:hover .i-sidebarcom-top {
		    color: #fff;
		}
		
		.sidebarcom-top:hover .sidebarcom-hover {
		    right: 36px;
		    transition: 0.2s linear;
		}
		
		/* 提示登录 */
		.sidebarcom-pop {
		    position: absolute;
		    right: -300px;
		    top: 0;
		    overflow: hidden;
		    width: 246px;
		    padding: 0 14px;
		    background-color: #fff;
		    border: 1px solid #cecece;
		    border-right: none;
		    transition: right 0.2s linear;
		    box-shadow: -5px 1px 8px rgba(0, 0, 0, 0.15);
		}
		
		.sidebarcom-pop-show {
		    right: 36px !important;
		}
		
		.sidebarcom-pop .el-icon-close {
		    float: right;
		    margin-top: 15px;
		    width: 18px;
		    height: 14px;
		    cursor: pointer;
		}
		
		.sidebarcom-my-pic {
		    display: block;
		    margin: 30px auto 20px;
		    width: 66px;
		    height: 66px;
		    border: 1px solid #fff;
		    box-shadow: 0 1px 3px #ddd;
		}
		
		.sidebarcom-my-inf {
		    padding-bottom: 20px;
		    text-align: center;
		}
		
		.sidebarcom-my-inf a {
		    margin: 0 3px;
		}
		
		.sidebarcom-my-oper {
		    clear: both;
		    overflow: hidden;
		    margin: 0 -14px;
		    width: 274px;
		    padding: 14px 0;
		    border-top: 1px solid #e5e5e5;
		    border-bottom: 1px solid #e5e5e5;
		    background-color: #f8f8f8;
		}
		
		.sidebarcom-my-message,
		.sidebarcom-my-order {
		    float: left;
		    position: relative;
		    width: 135px;
		    height: 66px;
		    padding-top: 10px;
		    text-align: center;
		    font-size: 9px;
		}
		
		.sidebarcom-my-order {
		    width: 136px;
		    border-right: 1px solid #e5e5e5;
		}
		
		.i-sidebarcom-mynub {
		    display: block;
		    -webkit-transform: scale(0.8);
		    -o-transform: scale(1);
		    width: 20px;
		    height: 20px;
		    line-height: 19px;
		    position: absolute;
		    right: 8px;
		    top: -5px;
		    background: #67c23a;
		    color: #fff;
		    font-size: 10px;
		    -webkit-text-size-adjust: none;
		    border-radius: 50%;
		}
		
		.i-sidebarcom-message,
		.i-sidebarcom-order {
		    font-size: 32px;
		    margin-bottom: 10px;
		    margin-left: auto;
		    margin-right: auto;
		    color: #c4c4c4;
		}
		
		.sidebarcom-my-oper p {
		    color: #333 !important;
		}
		
		.sidebarcom-my-oper p:hover {
		    color: #67c23a !important;
		}
		
	}
</style>
