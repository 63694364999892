<template>
	<div>
		<!-- <div style="height: 280px;"></div> -->
		<div class="com_Footer" @click="changeCurrentItem">
		    <div class="footer">
		        <div class="footer-t">
		            <div class="footer-doc">
		                <div class="footer-info">
		                    <!-- <div class="footer-link">
		                        <a @click="about_us">关于我们</a>
		                        <em>|</em>
		                        <a>法律法规</a>
		                        <em>|</em>
		                        <a>联系我们</a>
		                        <em>|</em>
		                        <a @click="news_index">新闻咨询</a>
		                        <em>|</em>
		                        <a @click="help">帮助中心</a>
		                    </div> -->
		                    <div class="footer-app">
		                        <div class="footer-app-l">
		                            <a class="footer-icon" @click="officialaccount_tips">
		                                <span class="iconfont ri-group-fill"></span>
		                                <p>公众号</p>
		                                <div class="official_account_tip" :class="officialaccounttips ? '' : 'officialaccounttip'">
											<img :src="foterList.accounturl" alt="" />
										</div>
		                            </a>
		                            <a class="footer-icon" @click="wechattips">
		                                <span class="iconfont icon-weixin"></span>
		                                <p>微信</p>
		                                <!-- <div class="footer-tip" :class="wechat_tips?'':'block'">请主人用微信手机端扫描【右侧二维码】进行关注哟！</div> -->
		                                <div class="footer-tip" :class="wechat_tips ? '' : 'block'">
											<img :src="foterList.wechaturl" alt="" />
										</div>
		                            </a>
		                        </div>
		                        <div class="footer-app-r">
		                            <a class="footer-icon" @click="iosClick">
		                                <span class="ios iconfont ri-apple-fill"></span>
		                                <p>IOS</p>
		                                <div class="ios-tip" :class="ios_tips ? '' : 'ios-active'">
											<img :src="foterList.iosurl" alt="" />
										</div>
		                            </a>
		                            <a class="footer-icon" @click="androidClick">
		                                <span class="android iconfont ri-android-fill"></span>
		                                <p>Android</p>
		                                <div class="android-tip" :class="android_tips ? '' : 'android-active'">
											<img :src="foterList.androidurl" alt="" />
										</div>
		                            </a>
		                        </div>
		                    </div>
		                </div>
		                <div class="footer-qrcode">
		                    <div>
		                        <img :src="foterList.codeurl" alt="" />
		                        <p>（{{foterList.codetitle}}）</p>
		                    </div>
		                    <!-- <div>
							  <img src="../../assets/img/png/监控.png" alt="">
							  <p>（智慧农业演示）</p>
							</div> -->
		                </div>
		                <div class="footer-concat">
		                    <p>客服热线</p>
		                    <strong class="en-font">{{foterList.fotphone}}</strong>
		                    <p>
		                        工作时间：
		                        <span class="en-font">{{foterList.fotdate}}</span>
		                    </p>
		                    <p>邮箱：{{foterList.mailbox}}</p>
		                </div>
		            </div>
		        </div>
		        <div class="footer-edtion">{{foterList.record}}</div>
		    </div>
		    <!-- <div class="footer_seat"></div> -->
		</div>
	</div>
    
</template>

<script>
export default {
    data() {
        return {
            wechat_tips: true,
            officialaccounttips: true,
            ios_tips: true,
            android_tips: true
        };
    },
	props:{
		foterList:{
			type: Object,
			value:{}
		}
	},
	created() {
		// console.log('获取到的：',this.foterList)
	},
    methods: {
        // 公众号
        officialaccount_tips() {
            this.officialaccounttips = !this.officialaccounttips;
			this.wechat_tips = true
			this.ios_tips = true
			this.android_tips = true
        },
        // 微信提示
        wechattips() {
            this.wechat_tips = !this.wechat_tips;
			this.officialaccounttips = true
			this.ios_tips = true
			this.android_tips = true
        },
        // ios
        iosClick() {
            this.ios_tips = !this.ios_tips;
			this.wechat_tips = true
			this.officialaccounttips = true
			this.android_tips = true
        },
        // 安卓
        androidClick() {
            this.android_tips = !this.android_tips;
			this.wechat_tips = true
			this.officialaccounttips = true
			this.ios_tips = true
        },
		changeCurrentItem(){
			this.$emit('changeCurrentItem',0)
		}
    },
    mounted() {
        // 页面加载后执行方法
    }
};
</script>

<style lang="scss">
.com_Footer {
    background: #2b2b2b;
    color: #fff;
	// position: fixed;
	// bottom: 0;
	// left: 0;
	// width: 100%;
	// z-index: 999;
	
	.footer {
	    width: 1200px;
	    margin: auto;
	    background: #2b2b2b;
	}
	
	.footer-t {
	    border-bottom: 1px solid #3d3d43;
	    padding-bottom: 30px;
	}
	
	.footer-doc {
	    width: 100%;
	    margin: 0 auto;
	    display: flex;
	    justify-content: space-between;
	}
	
	.footer-info {
	    float: left;
	    padding-top: 34px;
	}
	
	.footer-link a {
	    color: #a8a8a9 !important;
	    font-size: 14px;
	}
	
	.footer-link a:hover {
	    color: #67c23a !important;
	}
	
	.footer-link em {
	    margin: 0 13px;
	}
	
	.footer-app {
	    margin-top: 35px;
	    display: flex;
	    justify-content: space-between;
	}
	
	.footer-app-l {
	    display: flex;
	}
	
	.footer-icon {
	    color: #8c8c8c !important;
	    text-align: center;
	}
	
	.footer-app-l a {
	    margin-right: 28px;
	    position: relative;
	}
	
	.footer-icon span {
	    font-size: 25px;
	    border-radius: 50%;
	    width: 41px;
	    height: 41px;
	    line-height: 41px;
	    display: inline-block;
	    border: 1.4px solid;
	    margin-bottom: 10px;
		cursor: pointer;
	}
	
	.footer-icon span:hover {
	    background: #67c23a;
	    color: #fff !important;
	    border-color: #67c23a;
	}
	
	.ri-group-fill {
	    font-size: 20px !important;
	}
	
	.footer-app-l a .footer-tip {
	    position: absolute;
	    border-radius: 2px;
	    left: 135px;
	    top: -30px;
	    padding: 5px 10px;
	    background-color: #fff;
	    white-space: nowrap;
	    transition: 0.3s linear;
	    visibility: hidden;
	    cursor: default;
	    width: 100px;
	    height: 100px;
	    z-index: 99;
	}
	
	.footer-app-l a .footer-tip img {
	    width: 100%;
	    height: 100%;
	}
	
	.block {
	    visibility: inherit !important;
	    left: 60px !important;
	}
	
	.footer-app-l a .footer-tip:after {
	    content: '';
	    position: absolute;
	    bottom: 52px;
	    left: -10px;
	    width: 0;
	    height: 0;
	    border: 5px solid transparent;
	    border-top-color: #fff;
	    transform: rotate(90deg);
	}
	
	.official_account_tip {
	    position: absolute;
	    border-radius: 2px;
	    left: 130px;
	    top: -30px;
	    padding: 5px 10px;
	    background-color: #fff;
	    white-space: nowrap;
	    cursor: default;
	    visibility: hidden;
	    transition: 0.3s linear;
	    z-index: 9;
	}
	
	.official_account_tip:after {
	    content: '';
	    position: absolute;
	    top: 45%;
	    left: -10px;
	    width: 0;
	    height: 0;
	    border: 5px solid transparent;
	    border-top-color: #fff;
	    transform: rotate(90deg);
	    z-index: 99;
	}
	
	.official_account_tip img {
	    width: 100px;
	    height: 100px;
	}
	
	.officialaccounttip {
	    visibility: inherit !important;
	    left: 65px !important;
	}
	
	.footer-app-r {
	    display: flex;
	}
	
	.footer-app-r a {
	    margin-left: 28px;
	    position: relative;
	}
	
	.ri-apple-fill {
	    font-size: 22px !important;
	}
	
	.ios-tip {
	    position: absolute;
	    border-radius: 2px;
	    left: 130px;
	    top: -30px;
	    padding: 5px 10px;
	    background-color: #fff;
	    white-space: nowrap;
	    cursor: default;
	    visibility: hidden;
	    transition: 0.3s linear;
	    z-index: 9;
	    width: 100px;
	    height: 100px;
	}
	
	.ios-tip:after {
	    content: '';
	    position: absolute;
	    top: 45%;
	    left: -10px;
	    width: 0;
	    height: 0;
	    border: 5px solid transparent;
	    border-top-color: #fff;
	    transform: rotate(90deg);
	    z-index: 99;
	}
	
	.ios-tip img {
	    width: 100%;
	    height: 100%;
	}
	
	.ios-active {
	    visibility: inherit !important;
	    left: 60px !important;
	}
	
	.android-tip {
	    position: absolute;
	    border-radius: 2px;
	    left: 130px;
	    top: -30px;
	    padding: 5px 10px;
	    background-color: #fff;
	    white-space: nowrap;
	    cursor: default;
	    visibility: hidden;
	    transition: 0.3s linear;
	    z-index: 9;
	    width: 100px;
	    height: 100px;
	}
	
	.android-tip:after {
	    content: '';
	    position: absolute;
	    top: 45%;
	    left: -10px;
	    width: 0;
	    height: 0;
	    border: 5px solid transparent;
	    border-top-color: #fff;
	    transform: rotate(90deg);
	    z-index: 99;
	}
	
	.android-tip img {
	    width: 100%;
	    height: 100%;
	}
	
	.android-active {
	    visibility: inherit !important;
	    left: 60px !important;
	}
	
	.footer-qrcode {
	    text-align: center;
	    margin-left: -205px;
	    margin-top: 5px;
	    padding-top: 33px;
	    display: flex;
	}
	
	.footer-qrcode div {
	    margin-right: 25px;
	}
	
	.footer-qrcode img {
	    padding: 6px;
	    background-color: #fff;
	    width: 84px;
	    height: 84px;
	    display: block;
	}
	
	.footer-qrcode p {
	    display: block;
	    color: #a8a8a9;
	    font-size: 12px;
	    margin-top: 5px;
	}
	
	.footer-concat {
	    text-align: right;
	    color: #888;
	    padding-top: 30px;
	}
	
	.footer-concat p {
	    font-size: 14px;
	    margin-top: 10px;
	    display: block;
	}
	
	.footer-concat strong {
	    font-size: 30px;
	    color: #ababab;
	    font-weight: 400;
	    margin-top: 10px;
	    display: block;
	    margin-top: 10px;
	}
	
	.footer-edtion {
	    padding: 25px 0;
	    border-top: 1px solid #151515;
	    overflow: hidden;
	    text-align: center;
	    color: #cacaca;
	}
	
	.footer_seat {
	    height: 245px;
	    width: 100%;
	}
}
</style>
