<template>
	<div>
		<!-- 样式一 -->
		<div class="swiper" v-if="swiperlist.slide_type == 1">
			<!-- 登录提示 -->
			<div class="member">
			    <div class="member_login">
			        <div class="member_bd">
			            <div class="avatar_wrapper">
			                <a><img class="member_img" src="https://gw.alicdn.com/tps/i3/TB1yeWeIFXXXXX5XFXXuAZJYXXX-210-210.png_50x50.jpg" alt="" /></a>
			                <span class="member_name">Hi ！你好</span>
			            </div>
			        </div>
			        <div class="member_ft">
			            <div class="member_loginot">
			                <a>登录</a>
			                <a>注册</a>
			            </div>
			        </div>
			        <div class="member_list">
			            <a>
			                <img src="https://kundian.cqkundian.com/farm/1/png/20200317/ccbc96272621ada88e006025cd7a6aad1584434333" alt="" />
			                领券
			            </a>
			            <a>
			                <img src="https://kundian.cqkundian.com/farm/1/png/20200317/594eb037430bba14316e5979408d487b1584434334" alt="" />
			                会员
			            </a>
			            <a>
			                <img src="https://kundian.cqkundian.com/farm/1/png/20200317/3d0bab710ce57f17cb52b70afe32d5601584434334" alt="" />
			                帮助
			            </a>
			            <a>
			                <img src="https://kundian.cqkundian.com/farm/1/png/20200317/6524d42e9f03cfc732bd16e7477ea1831584434334" alt="" />
			                店铺
			            </a>
			        </div>
			        <div class="member_sign">
			            <div class="member_sign_com">
			                <p class="iconfont icon-qiandao1"></p>
			                <p>签到</p>
			            </div>
			        </div>
			    </div>
			</div>	
			
			<a-carousel :height="height" :autoplay="false">
				<div v-for="(item,index) in swiperlist.list" :key="index" :style="{height: height}">
					<img :src="item.url" :style="height"/>
				</div>
			</a-carousel>
			
			<div v-if="swiperlist.focus" class="dots">
				<span class="dot" v-for="(item,index) in swiperlist.list" :key='index' :style="{background:swiperlist.focusColor}"  @click="select_dot(index)"></span>
			</div>
			
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(swiperlist.id)"></i>
		</div>
		
		<!-- 样式二 -->
		<div class="swipervideo" v-if="swiperlist.slide_type == 2">
			<video-player class="vjs-custom-skin" :options="playerOptions"></video-player>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(swiperlist.id)"></i>
		</div>
		
		<!-- 样式三 -->
		<div class="swiperpc" v-if="swiperlist.slide_type == 3">
			<a-carousel height="620px" :interval='6000' :loop='true'>
			    <div v-for="(item,index) in swiperlist.list" :key="index">
			        <img :src="item.url" alt=""/>
			    </div>
			</a-carousel>
			
			<!-- 轮播文字 -->
			<div class="swiper_text">
			    <div style="text-align: center;">
			        <div class="swiper_text_title">
			            <div>{{swiperlist.titletext}}</div>
			        </div>
			        <div class="swiper_text_cont">{{swiperlist.vicetitle}}</div>
			        <!-- <div class="swiper_text_btn">
			            <div>注册试用</div>
			            <div>了解详情</div>
			        </div> -->
			    </div>
			</div>
			<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(swiperlist.id)"></i>
		</div>
	</div>
	
</template>

<script>
	//引入video样式
	// import 'video.js/dist/video-js.css'
	// import 'vue-video-player/src/custom-theme.css'
	export default{
		data(){
			return{
				imgUrl:'',
				catalog: 'catalog',
				playerOptions: {
					playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
					autoplay: true, //如果true,浏览器准备好时开始播放。
					controls: false, //控制条
					preload: 'auto', //视频预加载
					muted: false, //默认情况下将会消除任何音频。
					loop: true, //导致视频一结束就重新开始。
					language: 'zh-CN',
					// aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					// fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: 'video/mp4',
						src: 'https://0.ss.faisys.com/image/rimage/section/banner/default/flv.mp4'//你所放置的视频的地址，最好是放在服务器上
					}],
					poster: "http://39.106.117.192:8080/static/indexImg.png", //你的封面地址（覆盖在视频上面的图片）
					width: document.documentElement.clientWidth,
					notSupportedMessage: '此视频暂无法播放，请稍后再试' //允许覆盖Video.js无法播放媒体源时显示的默认信息。
				},
				land: [
				    {
				        land: '蔬菜'
				    },
				    {
				        land: '蔬菜'
				    },
				    {
				        land: '蔬菜'
				    },
				    {
				        land: '蔬菜'
				    },
				    {
				        land: '蔬菜'
				    },
				    {
				        land: '有机蔬菜'
				    }
				],
				adopt: [
				    {
				        adopt: '葡萄树'
				    },
				    {
				        adopt: '葡萄树'
				    },
				    {
				        adopt: '葡萄树'
				    },
				    {
				        adopt: '葡萄树'
				    },
				    {
				        adopt: '猕猴桃树'
				    },
				    {
				        adopt: '葡萄树'
				    }
				],
				product: [
				    {
				        product: '水果'
				    },
				    {
				        product: '水果'
				    },
				    {
				        product: '水果'
				    },
				    {
				        product: '水果'
				    },
				    {
				        product: '水果'
				    },
				    {
				        product: '水果'
				    }
				],
				travel: [
				    {
				        travel: '农家乐'
				    },
				    {
				        travel: '农家乐'
				    },
				    {
				        travel: '农家乐'
				    },
				    {
				        travel: '农家乐'
				    },
				    {
				        travel: '农家乐'
				    },
				    {
				        travel: '农家乐'
				    }
				],
			}
		},
		props:{
			swiperlist:{
				type:Object,
				value:{}
			}
		},
		created() {
			console.log(this.swiperlist)
		},
		watch:{
			'swiperlist.videourl':{
				deep: true,  // 深度监听
				handler(newVal,oldVal) {
					this.playerOptions.sources[0].src = newVal
				}
			}
		},
		computed: {
			height() {
				return `height: ${this.swiperlist.swiperHeight}px`
			}
		},
		methods:{
			catalogpage(){
				
			},
				
			select_dot(index){
				
			},
			showDeleteWrapper(id){
				this.$emit('changeDelet',id)
			}
		}
	}
</script>

<style lang="scss">
	.swiper {
	    width: 100%;
		height: 440px;
	    position: relative;
	    overflow: hidden;
		background: #fff;
		box-sizing: border-box;
		
		.el-image{
			width: 100%;
		}
		
		.el-carousel__container{
			height: 430px;
		}
	}
	
	.swiper:hover{
		border: 1px dashed #409EFF;
	}
	
	.deleteicon{
		position: absolute;
		bottom: 0px;
		right: 45px;
		z-index: 99999;
		display: none;
		cursor: pointer;
		color: #409EFF;
		font-size: 25px;
	}
	.swiper img {
	    width: 100%;
	}
	.dots {
	    width: 100%;
	    height: 30px;
	    position: absolute;
	    left: 0;
	    bottom: 0;
	    line-height: 30px;
	    text-align: center;
		z-index: 9999;
		display: none;
	}
	.dot {
	    display: inline-block;
	    width: 8px;
	    height: 8px;
	    border-radius: 50%;
	    background: #fff;
	    margin: 0 4px;
	}
	
	.swipervideo{
		width: 100%;
		// height: 400px;
		.vjs-custom-skin > .video-js{
			height: 600px;
			object-fit: fill;
		}
		
		.video-js .vjs-tech{
			height: 600px;
			object-fit: fill;
		}
	}
	
	/*目录分类*/
	.catalog_bg {
	    width: 100%;
	    background: #fff;
	}
	
	.catalog_bg>div {
	    width: 1200px;
	    margin: auto;
	    display: flex;
	    justify-content: space-between;
	    position: relative;
	}
	
	.catalog {
	    width: 1200px;
	    margin: auto;
	    position: relative;
	}
	
	.catalog_title {
	    height: 40px;
	    width: 200px;
	    line-height: 40px;
	    background: #000;
	    font-size: 16px;
	    display: flex;
	    align-items: center;
	    padding: 0 10px;
	    color: #fff;
	}
	
	.classification {
	    position: absolute;
	    top: 40px;
	    width: 200px;
	    height: 379px;
	    padding: 10px;
	    background: rgba(0,0,0,.6);
	    z-index: 99;
	}
	
	.classification .classification_title {
	    font-size: 17px;
	    text-align: center;
	    padding: 10px 0;
	    color: #fff;
	}
	
	.classification_title>i {
	    font-size: 20px;
	    color: #67C23A;
	}
	
	.classification_cont p {
	    display: inline-block;
	    padding: 5px 0;
	    width: 30%;
	    color: #fff;
	}
	
	.classification_cont p:hover {
	    color: #67c23a;
	}
	
	/* 提示登录 */
	.member {
	    position: absolute;
	    right: 18%;
	    top: 15%;
	    width: 290px;
	    height: 270px;
	    background: rgba(0,0,0,.6);
	    z-index: 99;
	    color: #fff;
	}
	
	.member_login{
	    position: relative;
	}
	
	.member_sign{
	    position: absolute;
	    top: 5px;
	    right: 10px;
	    z-index: 999;
	}
	
	.member_sign_com{
	    padding: 5px 10px;
	    border-radius: 10px;
	    text-align: center;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    cursor: pointer;
	}
	
	.member_sign_com .iconfont{
	    margin-right: 5px;
	}
	
	.member_bd {
	    position: relative;
	    height: 91px;
	    text-align: center;
	}
	
	.avatar_wrapper {
	    display: block;
	    margin: 6px auto 10px;
	    width: 74px;
	    height: 56px;
	}
	
	.avatar_wrapper>a {
	    display: inline-block;
	    border: 3px solid #fff;
	    border-radius: 50%;
	    background-clip: padding-box;
	    width: 50px;
	    height: 50px;
	    line-height: 50px;
	    overflow: hidden;
	    margin: 6px auto;
	}
	
	.avatar_wrapper .member_img {
	    display: inline-block;
	    width: 50px;
	    height: 50px;
	}
	
	.member_name {
	    display: block;
	    line-height: 27px;
	    height: 27px;
	}
	
	.member_ft {
	    height: 35px;
	    overflow: hidden;
	    margin-top: 10px;
	    padding-left: 15px;
	    padding-bottom: 20px;
	}
	
	.member_loginot {
	    height: 25px;
	    margin-top: 8px;
	    display: flex;
	    justify-content: space-around;
	}
	
	.member_loginot a {
	    display: inline-block;
	    width: 92px;
	    float: left;
	    line-height: 25px;
	    margin-right: 7px;
	    text-align: center;
	    color: #FFF;
	    background-image: -webkit-linear-gradient(left, #7ad477 0, #67c23a 100%);
	    background-image: -o-linear-gradient(left, #7ad477 0, #67c23a 100%);
	    background-image: linear-gradient(to right, #7ad477 0, #67c23a 100%);
	    background-repeat: repeat-x;
	    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7ad477', endColorstr='#67c23a', GradientType=1);
	    border-radius: 4px;
	    background-clip: padding-box;
	    font-size: 14px;
	    font-weight: 700;
	}
	
	.member-column a {
	    float: left;
	    margin-left: -1px;
	    width: 64px;
	    text-align: center;
	    /* border-left: 1px solid #FFF; */
	    color: #fff;
	}
	
	.member-column a:hover {
	    color: #67c23a;
	}
	
	.member-column a span {
	    height: 18px;
	    display: block;
	    font-size: 15px;
	    color: #67c23a;
	    margin-bottom: 2px;
	}
	
	.member_list {
	    width: 90%;
	    padding: 10px 0px;
	    margin: auto;
	    display: flex;
	    justify-content: space-around;
	    align-items: center;
	    text-align: center;
	    border-top: 1px dashed #ccc;
	    /* border-bottom: 1px dashed #ccc; */
	}
	
	.member_list a {
	    display: inline-block;
	    color: #fff;
	    font-size: 13px;
	}
	
	.member_list a:hover {
	    color: #67c23a !important;
	}
	
	.member_list img {
	    display: block;
	    width: 40px;
	    height: 40px;
	    margin-bottom: 2px;
	}
	
	.member_notice {
	    margin: 10px auto;
	    height: 140px;
	    width: 90%;
	    overflow: hidden;
	}
	
	.notice_news {
	    height: 36px;
	    width: 100%;
	    overflow: hidden;
	}
	
	.notice_news h2 {
	    font-size: 14px;
	    float: left;
	    color: #333;
	    margin-top: 12px;
	    font-weight: 400;
	}
	
	.notice_news a {
	    font-size: 12px;
	    text-align: center;
	    margin-top: 13px;
	    color: #888;
	    float: right;
	}
	
	.notice_news a:hover {
	    color: #67c23a !important;
	}
	
	.notice_news_content span {
	    display: block;
	    width: 250px;
	    height: 26px;
	    font-size: 12px;
	    line-height: 26px;
	}
	
	.notice_news_content .notice_news_sort {
	    color: #888;
	    margin-right: 7px;
	    margin-left: 10px;
	    float: left;
	    display: block;
	    width: 24px;
	    overflow: hidden;
	    height: 26px;
	}
	
	.notice_news_content .notice_news_text {
	    display: inline-block;
	    float: left;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 196px;
	}
	
	.notice_news_content a {
	    color: #5e5e5e;
	}
	
	.notice_news_content a:hover {
	    color: #67c23a !important;
	}
	
	// 样式三
	.swiperpc{
		position: relative;
		
		.el-carousel__arrow{
		    width: 50px;
		    height: 50px;
		    font-size: 26px;
		    background-color: rgba(31,45,61,.3);
		}
		
		.el-carousel__arrow--left{
		    left: 9%;
		}
		
		.el-carousel__arrow--right{
		    right: 9%;
		}
		
		.swiper_text{
		    position: absolute;
		    z-index: 9;
		    width: 770px;
		    text-align: center;
		    left: 50%;
		    top: 30%;
		    color: #fff;
		    margin-left: -385px;
		
		    &_title{
		        font-size: 45px;
		        font-weight: bold;
		        letter-spacing: 1px;
		        display: flex;
		        align-items: center;
		        justify-content: center;
		    }
		
		    &_cont{
		        font-size: 18px;
		        padding: 20px 0;
		    }
		
		    &_btn{
		        display: flex;
		        align-items: center;
		        justify-content: center;
		        margin-top: 20px;
		        font-size: 18px;
		
		        >div:first-child{
		            padding: 8px 22px;
		            background: #f06d18;
		            color: #fff;
		            border-radius: 20px;
		            cursor: pointer;
		            margin-right: 30px;
		        }
		
		        >div:last-child{
		            padding: 8px 22px;
		            border: 2px solid #f06d18;
		            color: #fff;
		            border-radius: 20px;
		            cursor: pointer;
		            transition: all .2s;
		        }
		        >div:last-child:hover{
		            background: #fff;
		            color: #f06d18;
		            border: 2px solid #fff;
		        }
		    }
		
		}
		
	}
</style>
