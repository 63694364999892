<template>
	<div id="functionavigation">
		<!-- 样式1 -->
		<div style="position: relative;" v-if="function_type == 1">
			<div :class="scroll > 600?'funactive':'funavt_cont'">
				<p :style="{color: oneColor}">优先推荐</p>
				<p :style="{color: twoColor}">优选店铺</p>
				<p :style="{color: threeColor}">租地种植</p>
				<p :style="{color: fourColor}">畜牧认养</p>
				<p :style="{color: fiveColor}">热销产品</p>
				<p :style="{color: sixColor}">新品上架</p>
				<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(functionid)"></i>
			</div>
		</div>
		<!-- 样式2 -->
		<div class="state_btn" v-if="function_type == 2">
		    <div class="state_btn_cont">
		        <div class="state_btn_com" v-for="(item, index) in statebtnlist" :key="index" :style="statebtn === item.id?selectColor:''">
		            <p class="iconfont" :class="item.icon"></p>
		            <div>
		                <p>{{ item.name }}</p>
		            </div>
		        </div>
				<i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(functionid)"></i>
		    </div>
		</div>
		
	</div>
</template>

<script>
	export default{
		data() {
			return {
				statebtn: 0,
				statebtnlist: [
				    {
				        name: '优先推荐',
				        icon: 'ri-thumb-up-line',
				        id: 0
				    },
				    {
				        name: '优选店铺',
				        icon: 'ri-home-4-line',
				        id: 1
				    },
				    {
				        name: '租地种植',
				        icon: 'ri-layout-grid-line',
				        id: 2
				    },
				    {
				        name: '认养领养',
				        icon: 'ri-plant-line',
				        id: 3
				    },
				    {
				        name: '热销产品',
				        icon: 'ri-fire-line',
				        id: 4
				    },
				    {
				        name: '新品上架',
				        icon: 'ri-vip-crown-2-line',
				        id: 5
				    }
				],
				scroll: 0
			}
		},
		computed:{
			selectColor(){
				return `background: ${this.SelectColor};color: ${this.SelectextColor}`
			}
		},
		props:{
			function_type:{
				type: Number
			},
			SelectColor: {
				type: String
			},
			SelectextColor:{
				type: String
			},
			oneColor:{
				type: String
			},
			twoColor:{
				type: String
			},
			threeColor:{
				type: String
			},
			fourColor:{
				type: String
			},
			fiveColor:{
				type: String
			},
			sixColor:{
				type: String
			},
			functionid:{
				type: Number
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed() {
		    window.removeEventListener('scroll', this.handleScroll);
		},
		methods: {
			showDeleteWrapper(id){
				this.$emit('changeDelet',id)
			},
			handleScroll() {
			    this.scroll = document.documentElement.scrollTop || document.body.scrollTop
			},
		},
	}
</script>

<style lang="scss">
	#functionavigation{
		// height: 250px;
		
		.deleteicon{
			position: absolute;
			bottom: 0px;
			right: -15px;
			z-index: 999;
			display: none;
			cursor: pointer;
			color: #409EFF;
			font-size: 25px;
		}
		
		.funactive{
			position: fixed;
			right: 14%;
			width: 35px;
			top: 12.5%;
			padding: 10px;
			background: #fff;
			border-radius: 6px;
			font-size: 13px;
			z-index: 999;
			
			>p{
				padding: 5px 0;
				line-height: 15px;
				border-bottom: 1px solid #eee;
			}
			
			>p:nth-child(6){
				border-bottom: none;
			}
		}
		
		.funavt_cont{
			position: absolute;
			right: 14%;
			width: 35px;
			top: 65px;
			padding: 10px;
			background: #fff;
			border-radius: 6px;
			font-size: 13px;
			
			>p{
				padding: 5px 0;
				line-height: 15px;
				border-bottom: 1px solid #eee;
			}
			
			>p:nth-child(6){
				border-bottom: none;
			}
		}
		
		.funavt_cont:hover{
			border: 1px dashed #409EFF;
		}
		
		
		.state_btn {
		    width: 100%;
		    // background: rgba(0, 0, 0, 1);
		
		    &_cont {
		        width: 1200px;
		        font-size: 16px;
		        margin: 20px auto 0px;
		        display: flex;
		        justify-content: space-between;
		        align-items: center;
				position: relative;
		    }
			
			&_cont:hover{
				border: 1px dashed #409EFF;
			}
			
		
		    .state_btn_com {
		        width: 14%;
		        height: 60px;
		        background: #efefef;
		        cursor: pointer;
		        border-radius: 5px;
		        display: flex;
		        justify-content: center;
		        align-items: center;
		        // color: #fff;
		
		        .iconfont {
		            font-size: 28px;
		            margin-right: 10px;
		        }
		    }
		
		    // .state_btn_com:hover {
		    //     background: #67c23a;
		    //     color: #fff;
		    // }
		
		    .statebtnactive {
		        background: #67c23a;
		        color: #fff;
		    }
		}
	}
</style>
